import { useEffect, useState } from "react";
import SalesManagerSelector from "../components/modules/salesManager/SalesManagerSelector";
import { ActionIcon, Select } from "@mantine/core";
import { APIFetchBranch } from "../api/sales-manager";
import {
  formatAmount,
  formatPercentValue,
} from "../utils/helper/cellFormatter";
import ClientRetentionTable from "../components/modules/clientRetention/ClientsTable";
import {
  APIGetClientRetentionDateRange,
  APIGetClientsGainedData,
  APIGetClientsLostData,
  APIGetClientsRetainedDownData,
  APIGetClientsRetainedUpData,
  APIGetClientsRetentionTotalData,
  APIGetOverallTotalData,
} from "../api/client-retention";
import { groupByKey } from "../utils/group";
import { Loader } from "../components/common/Loader";
import { useDispatch } from "react-redux";
import { setLoading } from "../store/Filter/actions";
import { INewClientGained } from "../types/clientRetention";
import { useNavigate } from "react-router-dom";

const ClientRetention = () => {
  const dispatch = useDispatch();
  const [dateRangeData, setDateRangeData] = useState<any>({});
  const [areaList, setAreaList] = useState([] as any);
  const [subAreaList, setSubAreaList] = useState([] as any);
  const [regionsList, setRegionsList] = useState([] as any);
  const [districtList, setDistrictList] = useState([] as any);
  const [branchesList, setBranchesList] = useState<any>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("All");
  const [hierarchy, setHierarchy] = useState({} as any);
  const [level, setLevel] = useState("Company");
  const [selectedLevelBranch, setSelectedLevelBranch] =
    useState<string>("Company");
  const [subAreaSelected, setSubAreaSelected] = useState(null);
  const [areaSelected, setAreaSelected] = useState(null);
  const [regionSelected, setRegionSelected] = useState(null);
  const [districtSelected, setDistrictSelected] = useState(null);
  const timelineOptions = [
    {
      value: "Last Year Rolling 12 Months",
      label: "Last Year Rolling 12 Months",
    },
    { value: "Last Year Rolling 30 Days", label: "Last Year Rolling 30 Days" },
    { value: "Last Year Rolling 90 Days", label: "Last Year Rolling 90 Days" },
    { value: "Last Year YTD", label: "Last Year YTD" },
    { value: "Rolling 12 Months", label: "Rolling 12 Months" },
    { value: "Rolling 30 Days", label: "Rolling 30 Days" },
    { value: "Rolling 90 Days", label: "Rolling 90 Days" },
    { value: "YTD", label: "YTD" },
    // { value: "Weekly", label: "Weekly" },
    // { value: "Rolling3Months", label: "Rolling 3 Months" },
    // { value: "Monthly", label: "Monthly" },
    // { value: "PreviousRolling3Months", label: "Last Year Rolling 3 Months" },
    // { value: "Quarterly", label: "Quarterly" },
    // { value: "PreviousRolling24Months", label: "Last Year Rolling 24 Months" },
  ];
  const [selectedTimeline, setSelectedTimeline] = useState("Rolling 12 Months");
  const [totalRevenue, setTotalRevenue] = useState<any>();
  const [totalGrowth, setTotalGrowth] = useState<any>();
  const [estimatedGrowthPercent, setEstimatedGrowthPercent] = useState<any>();
  const [clientRetentionData, setClientRetentionData] = useState<any>({
    clientsLostData: [],
    clientsRetainedUpData: [],
    clientsRetainedDownData: [],
    clientsGainedData: [],
    metricsTotalData: {},
    overallTotalData: [],
  });
  const [clientRetentionTablesData, setClientRetentionTablesData] =
    useState<any>([]);
  const [selectedTimelineRange, setSelectedTimelineRange] = useState<any>({
    last: {},
    previous: {},
  });

  useEffect(() => {
    // dispatch(setLoading(true));
    APIGetClientRetentionDateRange().then((res) => {
      setDateRangeData(groupByKey(res.data, "Types"));
      // dispatch(setLoading(false));
    });
  }, []);

  useEffect(() => {
    const selectedRange = dateRangeData[selectedTimeline];
    const last = selectedRange?.find((d: any) => d.Flag === 0);
    const previous = selectedRange?.find((d: any) => d.Flag === 1);
    setSelectedTimelineRange({ last, previous });
  }, [dateRangeData, selectedTimeline]);

  useEffect(() => {
    // dispatch(setLoading(true));
    APIFetchBranch(getSelectedLocation()).then((res: any) => {
      setBranchesList(res.data.map((d: any) => d.branch));
      // dispatch(setLoading(false));
    });
  }, [areaSelected, subAreaSelected, regionSelected, districtSelected, level]);

  useEffect(() => {
    fetchAllData();
  }, [
    areaSelected,
    subAreaSelected,
    regionSelected,
    districtSelected,
    level,
    selectedBranch,
  ]);

  const fetchAllData = async () => {
    dispatch(setLoading(true));
    const location =
      selectedBranch === "All" ? getSelectedLocation() : selectedBranch;
    let clientsDataPromises = [
      APIGetClientsLostData(location),
      APIGetClientsRetainedUpData(location),
      APIGetClientsRetainedDownData(location),
      APIGetClientsGainedData(location),
      APIGetClientsRetentionTotalData(location),
      APIGetOverallTotalData(location),
    ];
    const [
      clientsLost,
      clientsRetainedUp,
      clientsRetainedDown,
      clientsGained,
      tableTotalData,
      overallTotalData,
    ] = await Promise.all(clientsDataPromises);
    setClientRetentionData({
      clientsLostData: clientsLost.data,
      clientsRetainedUpData: clientsRetainedUp.data,
      clientsRetainedDownData: clientsRetainedDown.data,
      clientsGainedData: clientsGained.data,
      metricsTotalData: groupByKey(tableTotalData.data, "Types"),
      overallTotalData: groupByKey(overallTotalData.data, "Types"),
    });
    dispatch(setLoading(false));
  };

  const getSelectedLocation = () => {
    return level === "Company"
      ? "All"
      : districtSelected
      ? districtSelected
      : regionSelected
      ? regionSelected
      : subAreaSelected
      ? subAreaSelected
      : areaSelected
      ? areaSelected
      : "All";
  };

  useEffect(() => {
    const LostData = clientRetentionData.clientsLostData.filter(
      (data: any) => data.Types === selectedTimeline
    );
    const RetainedUpData = clientRetentionData.clientsRetainedUpData.filter(
      (data: any) => data.Types === selectedTimeline
    );
    const RetainedDownData = clientRetentionData.clientsRetainedDownData.filter(
      (data: any) => data.Types === selectedTimeline
    );
    const GainedData = clientRetentionData.clientsGainedData.filter(
      (data: any) => data.Types === selectedTimeline
    );
    let ClientsLostTotalRevenue = clientRetentionData.metricsTotalData[
      selectedTimeline
    ]?.find((d: any) => d.Metrics === "Clients Lost")?.Revenue;
    ClientsLostTotalRevenue =
      ClientsLostTotalRevenue < 0
        ? ClientsLostTotalRevenue
        : 0 - ClientsLostTotalRevenue;
    const ClientsRetainedUpTotalRevenue = clientRetentionData.metricsTotalData[
      selectedTimeline
    ]?.find((d: any) => d.Metrics === "Clients Retained Up")?.Revenue;
    const ClientsRetainedDownTotalRevenue =
      clientRetentionData.metricsTotalData[selectedTimeline]?.find(
        (d: any) => d.Metrics === "Clients Retained Down"
      )?.Revenue;
    const ClientsGainedTotalRevenue = clientRetentionData.metricsTotalData[
      selectedTimeline
    ]?.find((d: any) => d.Metrics === "Clients Gained")?.Revenue;

    setClientRetentionTablesData([
      {
        title: "Clients Lost",
        dateRangeDetails: {
          lastTitle: `Not Invoiced ${selectedTimelineRange.last?.Title}`,
          lastDateRange: `${selectedTimelineRange.last?.StartDate} - ${selectedTimelineRange.last?.EndDate}`,
          previousTitle: `Invoiced ${selectedTimelineRange.previous?.Title}`,
          previousDateRange: `${selectedTimelineRange.previous?.StartDate} - ${selectedTimelineRange.previous?.EndDate}`,
        },
        totalValue: ClientsLostTotalRevenue,
        data: LostData,
      },
      {
        title: "Clients Retained Up",
        dateRangeDetails: {
          lastTitle: `${selectedTimelineRange.last?.Title}`,
          lastDateRange: `${selectedTimelineRange.last?.StartDate} - ${selectedTimelineRange.last?.EndDate}`,
          previousTitle: `${selectedTimelineRange.previous?.Title}`,
          previousDateRange: `${selectedTimelineRange.previous?.StartDate} - ${selectedTimelineRange.previous?.EndDate}`,
        },
        totalValue: ClientsRetainedUpTotalRevenue,
        data: RetainedUpData,
      },
      {
        title: "Clients Retained Down",
        dateRangeDetails: {
          lastTitle: `${selectedTimelineRange.last?.Title}`,
          lastDateRange: `${selectedTimelineRange.last?.StartDate} - ${selectedTimelineRange.last?.EndDate}`,
          previousTitle: `${selectedTimelineRange.previous?.Title}`,
          previousDateRange: `${selectedTimelineRange.previous?.StartDate} - ${selectedTimelineRange.previous?.EndDate}`,
        },
        totalValue: ClientsRetainedDownTotalRevenue,
        data: RetainedDownData,
      },
      {
        title: "Clients Gained",
        dateRangeDetails: {
          lastTitle: `Invoiced ${selectedTimelineRange.last?.Title}`,
          lastDateRange: `${selectedTimelineRange.last?.StartDate} - ${selectedTimelineRange.last?.EndDate}`,
          previousTitle: `Not Invoiced ${selectedTimelineRange.previous?.Title}`,
          previousDateRange: `${selectedTimelineRange.previous?.StartDate} - ${selectedTimelineRange.previous?.EndDate}`,
        },
        totalValue: ClientsGainedTotalRevenue,
        data: GainedData,
      },
    ]);
    setTotalRevenue(
      clientRetentionData.overallTotalData[selectedTimeline]?.[0]?.TotalRevenue
    );
    setTotalGrowth(
      clientRetentionData.overallTotalData[selectedTimeline]?.[0]?.TotalGrowth
    );
    setEstimatedGrowthPercent(
      clientRetentionData.overallTotalData[selectedTimeline]?.[0]?.[
        "EstimatedGrowth%"
      ]
    );
  }, [clientRetentionData, selectedTimeline, selectedTimelineRange]);

  const updateOverallTotalData = async () => {
    const res = await APIGetOverallTotalData(getSelectedLocation());
    setClientRetentionData({
      ...clientRetentionData,
      overallTotalData: groupByKey(res.data, "Types"),
    });
  };

  const updateMetricsTotalData = async () => {
    const res = await APIGetClientsRetentionTotalData(getSelectedLocation());
    setClientRetentionData({
      ...clientRetentionData,
      metricsTotalData: groupByKey(res.data, "Types"),
    });
  };

  /**
   *  manipulates locally , the client gained data array
   */
  const clientGainedServices = {
    // Add new clients to the top of the list
    add: (newClients: INewClientGained[]) => {
      setClientRetentionData((prev: any) => ({
        ...prev,
        clientsGainedData: [...newClients, ...prev.clientsGainedData],
      }));
    },

    // Update existing client based on id
    update: (updatedClient: any) => {
      setClientRetentionData((prev: any) => {
        const updatedClients = prev.clientsGainedData.map((client: any) =>
          client.ID === updatedClient.ID
            ? { ...client, ...updatedClient }
            : client
        );
        return { ...prev, clientsGainedData: updatedClients };
      });
    },

    // Delete client based on id
    delete: (ID: string) => {
      setClientRetentionData((prev: any) => ({
        ...prev,
        clientsGainedData: prev.clientsGainedData.filter(
          (client: any) => client.ID !== ID
        ),
      }));
    },
  };

  const navigate = useNavigate();

  return (
    <main className="border-2 border-black h-[100vh] w-[100vw] bg-[#E7EDED] p-2 flex flex-col gap-5">
      <Loader />
      <header className="grid grid-cols-12 gap-2">
        {/* Home and back button */}
        <div className="col-span-2 col-start-1 flex gap-1">
          <a
            href="https://app.powerbi.com/groups/me/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionfdcec90ba28704f33c71?experience=power-bi"
            target="_blank"
            rel="noreferrer"
          >
            <ActionIcon>
              <i className="material-icons text-gray-500 hover:text-gray-600 cursor-pointer">
                home
              </i>
            </ActionIcon>
          </a>
          <ActionIcon onClick={() => navigate(-1)}>
            <i className="material-icons text-gray-500 hover:text-gray-600 cursor-pointer">
              arrow_back
            </i>
          </ActionIcon>
        </div>
        <div className="col-span-6 col-start-4 flex flex-col gap-2 items-center">
          <div className="font-medium py-1 px-10 w-fit bg-tertiary text-center text-white">
            Client Retention With Custom Goal
          </div>
          <SalesManagerSelector
            setLevel={setLevel}
            hierarchy={hierarchy}
            setHierarchy={setHierarchy}
            areaList={areaList}
            setAreaList={setAreaList}
            subAreaList={subAreaList}
            setSubAreaList={setSubAreaList}
            regionsList={regionsList}
            setRegionsList={setRegionsList}
            districtList={districtList}
            setDistrictList={setDistrictList}
            selectedLevelBranch={selectedLevelBranch}
            setSelectedLevelBranch={setSelectedLevelBranch}
            areaSelected={areaSelected}
            setAreaSelected={setAreaSelected}
            subAreaSelected={subAreaSelected}
            setSubAreaSelected={setSubAreaSelected}
            regionSelected={regionSelected}
            setRegionSelected={setRegionSelected}
            setDistrictSelected={setDistrictSelected}
            setSelectedBranch={setSelectedBranch}
          />
        </div>
        <div className="col-span-3 col-start-10 flex flex-col items-end justify-end gap-4">
          <div className="flex gap-5">
            <Select
              data={timelineOptions}
              value={selectedTimeline}
              onChange={(value: string) => setSelectedTimeline(value)}
              size="xs"
              label="Timeline"
            ></Select>
            <Select
              data={[
                "All",
                ...branchesList?.sort((a: string, b: string) =>
                  a.toLowerCase().localeCompare(b.toLowerCase())
                ),
              ]}
              value={selectedBranch}
              onChange={(value: string) => setSelectedBranch(value)}
              size="xs"
              label="Branch"
            ></Select>
          </div>
          <div className="text-[11px] flex gap-2">
            <div className="flex flex-col border border-slate-400 p-2">
              <span className="font-bold">Total Revenue</span>
              <span>{formatAmount(totalRevenue)}</span>
            </div>
            <div className="flex flex-col border border-slate-400 p-2">
              <span className="font-bold">Total Growth</span>
              <span>{formatAmount(totalGrowth)}</span>
            </div>
            <div className="flex flex-col border border-slate-400 p-2">
              <span className="font-bold">Estimated Growth %</span>
              <span>{formatPercentValue(estimatedGrowthPercent)}</span>
            </div>
          </div>
          <div className="text-[11px] border border-slate-400 p-2 font-bold flex justify-center items-center gap-1">
            <i className="material-icons text-gray-500 text-[15px]">
              star_outline
            </i>
            VMS Clients
          </div>
        </div>
      </header>
      <section className="flex flex-1 gap-2 overflow-auto">
        {clientRetentionTablesData.map((clientRetentionTable: any, i: any) => (
          <ClientRetentionTable
            key={i}
            retentionData={clientRetentionTable}
            timeline={selectedTimeline}
            branches={branchesList}
            currentBranch = {selectedBranch}
            updateOverallTotalData={updateOverallTotalData}
            updateMetricsTotalData={updateMetricsTotalData}
            clientGainedServices={clientGainedServices}
          />
        ))}
      </section>
    </main>
  );
};

export default ClientRetention;
