/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React from "react";

export function Select({
  options,
  className = "",
  defaultValue,
  placeHolder,
  onChange,
}: {
  options: Array<string | number>;
  className?: string;
  defaultValue?: string | number;
  placeHolder?: string;
  onChange?: (value: string | number) => void;
}) {
  const ref = React.useRef<HTMLSelectElement>(null);

  return (
    <div
      className="relative cursor-pointer"
      onClick={() => {
        if (ref.current) {
          ref.current.click();
        }
      }}
    >
      <select
        ref={ref}
        onChange={(e) => onChange && onChange(e.target.value)}
        className={`cursor-pointer appearance-none block w-full py-2 pl-3 pr-6 
           text-gray-700 bg-white border-b border-white focus:border-gray-400
            rounded-md focus:outline-none ${className}`}
        defaultValue={defaultValue || ""}
      >
        {placeHolder && (
          <option value="" disabled>
            {placeHolder}
          </option>
        )}
        {options.map((option) => (
          <option value={option} className="capitalize" key={option}>
            {option}
          </option>
        ))}
      </select>
      <i
        className={`material-icons text-gray-500 text-xs
           absolute top-1.5 right-1 rotate-90`}
      >
        code
      </i>
    </div>
  );
}
