import { groupByKey } from "../group";

export const formatLocationData = (data: any[]) => {
  const formattedData = data.map((v: any) => ({
    ...v,
    Areaname:
      v["Areaname"] && v["Areaname"] !== "" ? v["Areaname"] : "Ungrouped",
    "Sub Area Name":
      v["Sub Area Name"] && v["Sub Area Name"] !== ""
        ? v["Sub Area Name"]
        : "Ungrouped",
    Region: v["Region"] && v["Region"] !== "" ? v["Region"] : "Ungrouped",
    District:
      v["District"] && v["District"] !== "" ? v["District"] : "Ungrouped",
  }));

  const areas = Object.keys(groupByKey(formattedData, "Areaname")).filter(
    (v) => v !== "Ungrouped"
  );
  const subareas = Object.keys(
    groupByKey(formattedData, "Sub Area Name")
  ).filter((v) => v !== "Ungrouped");
  const regions = Object.keys(groupByKey(formattedData, "Region")).filter(
    (v) => v !== "Ungrouped"
  );
  const districts = Object.keys(groupByKey(formattedData, "District")).filter(
    (v) => v !== "Ungrouped"
  );
  const branches = Object.keys(groupByKey(formattedData, "Branch"));

  let locationHier: any = {};
  const topHier = groupByKey(formattedData, "Areaname");
  Object.keys(topHier).forEach((v1: any) => {
    let temp = groupByKey(topHier[v1], "Region");
    let subHier = {};
    for (let v2 in temp) {
      subHier = {
        ...subHier,
        [v2]: Object.values(temp[v2]).map((v: any) => v.Branch),
      };
    }
    locationHier = {
      ...locationHier,
      [v1]: subHier,
    };
  });

  return {
    areas,
    subareas,
    regions,
    districts,
    branches,
    locationHier,
  };
};
