import { useEffect, useState } from "react";
import { BonusReviewFilters } from "../components/modules/bonusReview/BonusReviewFilters";
import HomeButton from "../components/common/HomeButton";
import BonusReviewCharts from "../components/modules/bonusReview/BonusReviewCharts";
import {
  APICalculateTotalScore,
  APIDeleteBonusReview,
  APIFetchEmployeeAndManager,
  APIFetchLineChart,
  APIFetchScoreAndTasks,
  APIInsertUpdateBonusReview,
} from "../api/bonus-review";
import { groupByKey } from "../utils/group";
import { getCalendarWeekLabels, setLoading } from "../store/Filter/actions";
import { useDispatch } from "react-redux";
import { Chart, FilterType } from "../components/modules/bonusReview/type";
import { Loader } from "../components/common/Loader";
import { Button, HoverCard, Modal, Table } from "@mantine/core";

const BonusReviewReport = () => {
  const dispatch = useDispatch();
  const [selectedLevel, setSelectedLevel] = useState<string>("All");
  const [lineChartData, setLineChartData] = useState({});
  const [employeeAndManager, setEmployeeAndManager] = useState<
    Array<{ EmployeeName: string; ManagerName: string }>
  >([]);
  const [scoreAndTasks, setScoreAndTasks] = useState<
    Array<{
      id: number;
      EmployeeScore: number;
      ManagerScore: number;
      Task: string;
    }>
  >([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterType>({
    area: "All",
    subarea: "All",
    region: "All",
    district: "All",
    branch: "All",
  });
  const [totalScore, setTotalScore] = useState<Record<string, number>>({});

  const getWeekDates = async () => {
    dispatch(await getCalendarWeekLabels(new Date().getFullYear()));
  };

  const charts: Chart[] = [
    {
      title: "Revenue",
      typeValue: "Revenue",
    },
    { title: "Operating Income", typeValue: "OperatingIncome" },
  ];

  const getSelectedLocation = () => {
    return selectedLevel === "Branch"
      ? selectedFilter.branch
      : selectedLevel === "District"
      ? selectedFilter.district
      : selectedLevel === "Region"
      ? selectedFilter.region
      : selectedLevel === "Sub Area"
      ? selectedFilter.subarea
      : selectedLevel === "Area"
      ? selectedFilter.area
      : "All";
  };

  const fetchChart = async () => {
    dispatch(setLoading(true));
    try {
      const res = await APIFetchLineChart(selectedLevel, getSelectedLocation());
      setLineChartData(groupByKey(res.data, "CalendarYear"));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  /**
   * checks if obj2 has all the keys on obj1 with same values
   */
  const hasSameValues = (
    obj1: Record<string, any>,
    obj2?: Record<string, any>
  ): boolean => {
    if (!obj2) return false;
    return Object.entries(obj1).every(([key, value]) => obj2[key] === value);
  };

  const services = {
    fetchTable: async () => {
      dispatch(setLoading(true));
      try {
        const res = await APIFetchEmployeeAndManager(getSelectedLocation());
        setEmployeeAndManager(res.data);
      } catch (err) {
        console.log(err);
      } finally {
        dispatch(setLoading(false));
      }
    },
    fetchScoreAndTasks: async () => {
      dispatch(setLoading(true));
      try {
        const res = await APIFetchScoreAndTasks(getSelectedLocation());
        setScoreAndTasks(res?.data);
      } catch (err) {
        console.log(err);
      } finally {
        dispatch(setLoading(false));
      }
    },
    // fetchManagerDetails: async () => {
    //   dispatch(setLoading(true));
    //   try {
    //     const res = await APIFetchManagerDetails();
    //     console.log("managers details");
    //     console.log(res);
    //   } catch (err) {
    //     console.log(err);
    //   } finally {
    //     dispatch(setLoading(false));
    //   }
    // },

    addBonusReview: async () => {
      dispatch(setLoading(true));
      try {
        if (!requiredFieldsHaveValue()) return;
        const res = await APIInsertUpdateBonusReview(
          "",
          getSelectedLocation(),
          newRowData.EmployeeScore!,
          newRowData.ManagerScore!,
          newRowData.Task!,
          localStorage.getItem("username") ?? ""
        );
        const id = res.data?.[0]?.[0].MaxID;

        // setScoreAndTasks(prev => ({{...newRowData},...prev}));
        clearNewRow();
        setScoreAndTasks((prev) => [
          {
            id,
            EmployeeScore: newRowData?.EmployeeScore!,
            ManagerScore: newRowData?.ManagerScore!,
            Task: newRowData.Task!,
          },
          ...prev,
        ]);
        await services.calculateTotalScore();
      } catch (err) {
        console.log(err);
      } finally {
        dispatch(setLoading(false));
      }
    },
    updateBonusReview: async () => {
      dispatch(setLoading(true));
      try {
        if (!requiredFieldsHaveValue) return;
        if (
          hasSameValues(
            rowUnderUpdate,
            scoreAndTasks.find((i) => i.id === rowUnderUpdate.id)
          )
        )
          return; // data didn't change

        const res = await APIInsertUpdateBonusReview(
          rowUnderUpdate.id,
          getSelectedLocation(),
          rowUnderUpdate.EmployeeScore,
          rowUnderUpdate.ManagerScore,
          rowUnderUpdate.Task,
          localStorage.getItem("username") ?? ""
        );
        setScoreAndTasks((prev) =>
          prev.map((item) => {
            if (item.id !== rowUnderUpdate.id) return item;
            else
              return {
                ...rowUnderUpdate,
              };
          })
        );
        await services.calculateTotalScore();
      } catch (err) {
        console.log(err);
      } finally {
        clearNewRow();
        setRowUnderUpdate({});
        dispatch(setLoading(false));
      }
    },
    deleteBonusReview: async () => {
      const id = itemIdToDelete;
      setItemIdToDelete(undefined);
      dispatch(setLoading(true));
      try {
        if (!id) return;
        await APIDeleteBonusReview(id);
        setScoreAndTasks((prev) => prev.filter((i) => i.id !== id));
        await services.calculateTotalScore();
      } catch (err) {
        console.log(err);
      } finally {
        setItemIdToDelete(undefined);
        dispatch(setLoading(false));
      }
    },
    calculateTotalScore: async () => {
      dispatch(setLoading(true));
      try {
        const res = await APICalculateTotalScore(getSelectedLocation());
        setTotalScore(res.data?.[0]?.[0]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    },
  };

  useEffect(() => {
    fetchChart();
    services.fetchTable();
    services.fetchScoreAndTasks();
    services.calculateTotalScore();
  }, [selectedLevel, selectedFilter]);

  useEffect(() => {
    getWeekDates();
  }, []);

  const [showEmptyRow, setShowEmptyRow] = useState(false);

  const [newRowData, setNewRowData] = useState<{
    EmployeeScore?: number;
    ManagerScore?: number;
    Task?: string;
  }>({ EmployeeScore: undefined, ManagerScore: undefined, Task: undefined });

  const addNewRowData = (
    key: keyof typeof newRowData,
    value: string | number
  ) => {
    setNewRowData((prev) => ({ ...prev, [key]: value.toString() }));
  };

  const clearNewRow = () => {
    setShowEmptyRow(false);
    setNewRowData({
      EmployeeScore: undefined,
      ManagerScore: undefined,
      Task: undefined,
    });
  };
  const requiredFieldsHaveValue = () =>
    Object.values(newRowData).every(
      (d) => d !== null && d !== undefined && d !== ""
    );

  const [rowUnderUpdate, setRowUnderUpdate] = useState<any>({});
  const updateRowData = (
    key: keyof typeof rowUnderUpdate,
    value: string | number
  ) => {
    setRowUnderUpdate((prev: any) => ({ ...prev, [key]: value }));
  };

  const [itemIdToDelete, setItemIdToDelete] = useState<number | undefined>();
  return (
    <main className="clients-table flex flex-col gap-6 min-h-[100vh] bg-[#E2EFED] pt-1 px-6 overflow-hidden relative">
      <Loader />
      <div className="top-0 left-0 fixed">
        <HomeButton adaOnly={null} />
      </div>
      <header className="flex flex-col gap-2">
        <div className="mx-auto font-semibold text-lg bg-tertiary px-16 text-white py-1">
          Bonus Review Report
        </div>
        <div className="flex justify-between items-end w-full mt-2">
          <BonusReviewFilters
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            selectedLevel={selectedLevel}
            setSelectedLevel={setSelectedLevel}
          />
        </div>
        <div className="w-fit bg-tertiary text-white min-w-[100px] text-center py-1 px-4 rounded-md mx-auto mt-2">
          {getSelectedLocation()}
        </div>
      </header>
      <section className="flex justify-center gap-6">
        {charts.map((chart: Chart, index: number) => {
          return (
            <BonusReviewCharts
              title={chart.title}
              type={chart.typeValue}
              lineChartData={lineChartData}
              key={chart.title + index.toString()}
            />
          );
        })}
      </section>

      {/* Bonus review table */}
      <section className="grow overflow-hidden h-full flex flex-col gap-2 mx-auto w-full xl:w-[1100px] ">
        {employeeAndManager?.length > 0 &&
          (showEmptyRow ? (
            <div className="flex gap-2">
              <button
                className="select-none flex items-center gap-1 text-xs px-2 py-[1px] rounded-md bg-red-400 cursor-pointer hover:bg-red-500 text-white"
                onClick={clearNewRow}
                title="Cancel"
              >
                <i className="material-icons text-white text-sm">cancel</i>
                Cancel
              </button>
            </div>
          ) : (
            <button
              onClick={() => setShowEmptyRow(true)}
              className="select-none flex items-center gap-1 text-xs px-2 py-[1px] rounded-md bg-blue-500 cursor-pointer hover:bg-blue-600 text-white w-fit"
            >
              <i className="material-icons text-white text-sm">add_circle</i>
              Add data
            </button>
          ))}

        <div className="flex flex-1 overflow-auto max-h-48">
          <Table
            fontSize={12}
            className="bg-white border border-t-0 border-b h-fit"
            highlightOnHover
            withColumnBorders
            verticalSpacing={2}
            horizontalSpacing={4}
          >
            <thead className="sticky top-0 z-30">
              {employeeAndManager.map((data) => (
                <tr
                  className="bg-tertiary"
                  key={data.EmployeeName + data.ManagerName}
                >
                  <th style={{ color: "white" }} className="w-1/4">
                    {data.EmployeeName}
                  </th>
                  <th style={{ color: "white" }} className="w-1/4">
                    {data.ManagerName}
                  </th>
                  <th style={{ color: "white" }} className="w-2/5">
                    Task
                  </th>
                  <th style={{ color: "white" }} className="">
                    Action
                  </th>
                </tr>
              ))}
            </thead>
            <tbody>
              {showEmptyRow ? (
                <tr>
                  <td className="w-1/4">
                    <input
                      type="number"
                      placeholder="Employee score"
                      onChange={(e) =>
                        addNewRowData("EmployeeScore", e.target.value)
                      }
                      className="w-full"
                      autoFocus
                    />
                  </td>
                  <td className="w-1/4">
                    <input
                      type="number"
                      placeholder="Manager score"
                      onChange={(e) =>
                        addNewRowData("ManagerScore", e.target.value)
                      }
                      defaultValue={parseFloat(rowUnderUpdate?.ManagerScore)}
                      className="w-full"
                    />
                  </td>
                  <td className="w-2/5">
                    <input
                      type="text"
                      placeholder="Task"
                      onChange={(e) => addNewRowData("Task", e.target.value)}
                      className="w-full"
                    />
                  </td>
                  <td>
                    <span className="w-full h-full flex justify-start gap-2 items-center">
                      <HoverCard shadow="md">
                        <HoverCard.Target>
                          <button
                            onClick={() => {
                              services.addBonusReview();
                            }}
                          >
                            <i className="material-icons actionIcons">save</i>
                          </button>
                        </HoverCard.Target>
                        {requiredFieldsHaveValue() ? (
                          ""
                        ) : (
                          <HoverCard.Dropdown>
                            <div className="flex gap-1">
                              <i className="material-icons text-[15px] text-red-600">
                                warning
                              </i>
                              <p>Fill in all the fields to save</p>
                            </div>
                          </HoverCard.Dropdown>
                        )}
                      </HoverCard>
                      <button onClick={clearNewRow}>
                        <i
                          className="material-icons actionIcons"
                          title="Cancel"
                        >
                          cancel
                        </i>
                      </button>
                    </span>
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {employeeAndManager?.length > 0 && scoreAndTasks?.length === 0 ? (
                showEmptyRow ? (
                  <></>
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center text-base">
                      No data available. Click 'Add data' to add new items.
                    </td>
                  </tr>
                )
              ) : (
                scoreAndTasks.map((item) =>
                  rowUnderUpdate?.id === item.id ? (
                    <tr key={item.id} className="bg-tertiary/10">
                      <td className="w-1/4">
                        <input
                          type="number"
                          placeholder="Employee score"
                          onChange={(e) =>
                            updateRowData("EmployeeScore", e.target.value)
                          }
                          defaultValue={parseFloat(
                            rowUnderUpdate?.EmployeeScore
                          )}
                          className="w-full"
                          autoFocus
                        />
                      </td>
                      <td className="w-1/4">
                        <input
                          type="number"
                          placeholder="Manager score"
                          defaultValue={parseFloat(
                            rowUnderUpdate?.ManagerScore
                          )}
                          onChange={(e) =>
                            updateRowData("ManagerScore", e.target.value)
                          }
                          className="w-full"
                        />
                      </td>
                      <td className="w-2/5">
                        <input
                          type="text"
                          placeholder="Task"
                          defaultValue={rowUnderUpdate?.Task}
                          onChange={(e) =>
                            updateRowData("Task", e.target.value)
                          }
                          className="w-full"
                        />
                      </td>
                      <td>
                        <span className="w-full h-full flex justify-start gap-2 items-center">
                          <button
                            onClick={services.updateBonusReview}
                            className="focus:outline-1"
                          >
                            <i
                              className="material-icons actionIcons w-fit"
                              title="Save"
                            >
                              save
                            </i>
                          </button>
                          <button>
                            <i
                              className="material-icons actionIcons w-fit"
                              title="Cancel edit"
                              onClick={() => {
                                setRowUnderUpdate({});
                              }}
                            >
                              cancel
                            </i>
                          </button>
                        </span>
                      </td>
                    </tr>
                  ) : (
                    <tr key={item.id}>
                      <td className="w-1/4">{item.EmployeeScore}</td>
                      <td className="w-1/4">{item.ManagerScore}</td>
                      <td className="w-2/5">{item.Task}</td>
                      <td>
                        <span className="w-full h-full flex justify-start gap-2 items-center">
                          <button
                            onClick={() => {
                              setRowUnderUpdate(item);
                            }}
                          >
                            <i
                              className="material-icons actionIcons"
                              title="Edit"
                            >
                              edit
                            </i>
                          </button>
                          <button
                            onClick={() => {
                              setItemIdToDelete(item.id);
                            }}
                          >
                            <i
                              className="material-icons actionIcons"
                              title="Delete"
                            >
                              delete
                            </i>
                          </button>
                        </span>
                      </td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </Table>
        </div>
      </section>

      {/* score section */}
      {employeeAndManager?.length > 0 && (
        <section className="mx-auto">
          <div className="mb-4 grid grid-cols-3 gap-2">
            <div className="border border-stone-500  ">
              <p className="font-bold text-center text-sm px-4 py-1 border-b border-stone-500">
                Employee Total
              </p>
              <p className="text-sm py-3 text-center">
                {totalScore?.EmployeeTotal ?? "-"}
              </p>
            </div>
            <div className="border border-stone-500  ">
              <p className="font-bold text-center text-sm px-4 py-1 border-b border-stone-500">
                Manager Total
              </p>
              <p className="text-sm py-3 text-center">
                {totalScore?.ManagerTotal ?? "-"}
              </p>
            </div>

            <div className="border border-stone-500 self-start w-full">
              <p className="font-bold text-center text-sm px-2 py-1 border-b border-stone-500">
                Score
              </p>
              <p className="text-sm py-3 text-center">
                {totalScore?.Score ?? "-"}
              </p>
            </div>
          </div>
        </section>
      )}
      <Modal
        opened={Boolean(itemIdToDelete?.toString())}
        onClose={() => setItemIdToDelete(undefined)}
        centered
        title="Are you sure you want to delete the data"
      >
        <p className="text-sm text-gray-500 mb-5">This action is permanent.</p>

        <div className="flex justify-end gap-3">
          <Button color="gray" onClick={() => setItemIdToDelete(undefined)}>
            Cancel
          </Button>
          <Button color="red" onClick={services.deleteBonusReview}>
            Delete
          </Button>
        </div>
      </Modal>
    </main>
  );
};

export default BonusReviewReport;
