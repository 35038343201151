import { Table } from "@mantine/core";
import {
  getCalculatedPercentage,
  getMonthWiseSumOfData,
  getPerOverLastYear,
} from "./utils/tableFormulae";
import { TABLE_MONTH_HEADERS } from "../../../utils/mock-data/financial-report";
import {
  CATEGORIES,
  HIGHLIGHTED_PARTICULARS,
  INPUT_ROWS,
  ITEMS,
  NULL_CELLS,
  NULL_ITEMS,
  PerOverLYRows,
  QUARTERLIST,
  quarterMonths,
} from "./utils/constants";
import {
  formatAmount,
  formatPercentValue,
} from "../../../utils/helper/cellFormatter";

const TableComponent = (props: any) => {
  const {
    tableHeaders,
    tableData,
    netSalesYTD,
    netSalesQuarters,
    activeCell,
    setActiveCell,
    branchSelected,
    level,
    editedData,
    calcData,
    highlight,
    setHighlight,
    handleChangeRowData,
    getHighlightStyle,
    handleHilight,
    handleInputChange,
  } = props;

  const handleFocus = (
    branchSelected: string,
    categories: string,
    items: string,
    header: string
  ) => {
    setActiveCell((prevState: any) => ({
      branch: branchSelected,
      category: categories,
      item: items,
      header: prevState.header ? [...prevState.header, header] : [header],
    }));
    handleHilight(branchSelected, categories, items, header);
  };

  const handleKeyDown = (
    e: any,
    branchSelected: string,
    categories: string,
    items: string,
    header: string
  ) => {
    if (e.key === "Delete") {
      handleInputChange(branchSelected, categories, items, header, "");
    } else if (e.key === "Tab") {
      handleChangeRowData(branchSelected, categories, items);
    } else if (e.key === "Enter") {
      handleChangeRowData(branchSelected, categories, items);
      setHighlight({
        branch: null,
        category: null,
        item: null,
        header: null,
      });
    }
  };

  const getClassName = (items: string, level: string, nullValueRows: any) => {
    return `flex justify-between sticky left-0 z-20  ${
      HIGHLIGHTED_PARTICULARS.includes(items.trim()) || nullValueRows
        ? "font-bold"
        : ""
    } ${
      items === ITEMS.STAFFINGMANAGER
        ? "bg-[#ffd0a3]"
        : items === ITEMS.INCOMEMODEL
        ? "bg-[#CCC0DA]"
        : HIGHLIGHTED_PARTICULARS.includes(items.trim()) && level !== "Branch"
        ? "bg-tertiary text-white"
        : "bg-white"
    } `;
  };

  return (
    <Table
      fontSize={11}
      verticalSpacing={3}
      withBorder
      withColumnBorders
      horizontalSpacing={1}
    >
      <thead>
        <tr>
          <th className="min-w-[140px] sticky left-0 top-0 z-30 bg-white"></th>
          {tableHeaders.map((header: string, index: number) => {
            return (
              <th
                key={index}
                className={`text-center sticky top-0 bg-white z-10 h-8 ${
                  header.length === 0 ? "min-w-[5px]" : "min-w-[65px]"
                }`}
              >
                {header}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="goal-table">
        {tableData &&
          tableData.length !== 0 &&
          tableData
            .filter((data: any) =>
              level === "Branch"
                ? isNaN(parseFloat(data.items)) &&
                  data.items !== ITEMS.ACQUISITION_EXPENSE
                : isNaN(parseFloat(data.items)) &&
                  !NULL_ITEMS.includes(data.items)
            )
            .map((data: any, index: number) => {
              const currentYTD = getMonthWiseSumOfData(
                data,
                TABLE_MONTH_HEADERS()
              );
              const percentageYTD = getCalculatedPercentage(
                currentYTD,
                netSalesYTD
              );
              const perOverLastYear = getPerOverLastYear(
                currentYTD,
                data.lastyeargoal
              );
              // Get current data values for each quarter
              const currentQuarters = {
                Q1: getMonthWiseSumOfData(data, quarterMonths.Q1),
                Q2: getMonthWiseSumOfData(data, quarterMonths.Q2),
                Q3: getMonthWiseSumOfData(data, quarterMonths.Q3),
                Q4: getMonthWiseSumOfData(data, quarterMonths.Q4),
              };
              // Calculate percentages for each quarter
              const percentageQuarters = {
                Q1: getCalculatedPercentage(
                  currentQuarters.Q1,
                  netSalesQuarters.Q1
                ),
                Q2: getCalculatedPercentage(
                  currentQuarters.Q2,
                  netSalesQuarters.Q2
                ),
                Q3: getCalculatedPercentage(
                  currentQuarters.Q3,
                  netSalesQuarters.Q3
                ),
                Q4: getCalculatedPercentage(
                  currentQuarters.Q4,
                  netSalesQuarters.Q4
                ),
              };

              const nullValueRows = data.items.includes(":");
              const nullValueCells = NULL_CELLS.includes(data.items);
              const calculatedRow: any =
                calcData.find(
                  (d: any) =>
                    d.items === data.items && d.Categories === data.Categories
                ) ?? "";

              const isCategoryChanged =
                index > 0 &&
                data.Categories !== tableData[index - 1].Categories;

              const perOverlyvalue =
                nullValueRows ||
                nullValueCells ||
                !PerOverLYRows.includes(data.items)
                  ? null
                  : level === "Branch"
                  ? INPUT_ROWS.includes(data.items.trim())
                    ? formatPercentValue(perOverLastYear.toString())
                    : formatPercentValue(calculatedRow["Peroverlastyear"])
                  : formatPercentValue(data["Peroverlastyear"]);

              return (
                <>
                  {isCategoryChanged && (
                    <tr className="">
                      <td className="bg-white h-7" colSpan={12}></td>
                    </tr>
                  )}
                  <tr
                    className={` ${
                      level === "Branch" &&
                      HIGHLIGHTED_PARTICULARS.includes(data.items.trim())
                        ? "border-y-2 border-stone-400"
                        : "border-y-[1px]"
                    }`}
                  >
                    <td
                      key={data.items}
                      className={getClassName(data.items, level, nullValueRows)}
                      style={{ paddingLeft: 5 }}
                    >
                      {data.items}
                    </td>
                    {TABLE_MONTH_HEADERS().map(
                      (header: string, index: number) => {
                        const editedRow = editedData.find(
                          (d: any) =>
                            d.Branch === branchSelected &&
                            d.items === data.items &&
                            d.Categories === data.Categories
                        )?.[header];

                        const operatingIncome = tableData.find(
                          (d: any) =>
                            d.items === ITEMS.OPERATINGINCOME &&
                            d.Categories === CATEGORIES.OTHERS
                        )?.[header];

                        const incomeModel = tableData.find(
                          (d: any) =>
                            d.items === ITEMS.INCOMEMODEL &&
                            d.Categories === CATEGORIES.INCOME
                        )?.[header];

                        // Calculate Income Model %
                        const incomeModelPercentage =
                          incomeModel && incomeModel !== 0
                            ? ((operatingIncome || 0) / incomeModel) * 100
                            : 0;

                        return (
                          <td
                            key={index}
                            className={`${
                              data.items === ITEMS.UNDERINCOMEMODEL
                                ? "bg-white"
                                : data.flags === 1
                                ? "bg-[#fceec5]"
                                : data.items === ITEMS.STAFFINGMANAGER
                                ? "bg-[#ffd0a3]"
                                : data.items === ITEMS.INCOMEMODEL
                                ? "bg-[#CCC0DA]"
                                : HIGHLIGHTED_PARTICULARS.includes(
                                    data.items.trim()
                                  ) && level !== "Branch"
                                ? "bg-tertiary text-white"
                                : "bg-white"
                            }`}
                            style={getHighlightStyle(
                              branchSelected,
                              data.Categories,
                              data.items,
                              header
                            )}
                          >
                            {data.flags === 1 ? (
                              (activeCell.branch === data.Branch &&
                                activeCell.category === data.Categories &&
                                activeCell.item === data.items &&
                                activeCell.header?.includes(header)) ||
                              (highlight.branch === data.Branch &&
                                highlight.category === data.Categories &&
                                highlight.item === data.items &&
                                highlight.header === header) ? (
                                <input
                                  type="number"
                                  style={{ border: "none" }}
                                  className={`w-[65px]  ${
                                    data.items === ITEMS.UNDERINCOMEMODEL
                                      ? "bg-white"
                                      : "bg-[#fceec5]"
                                  }`}
                                  onFocus={() =>
                                    handleHilight(
                                      branchSelected,
                                      data.Categories,
                                      data.items,
                                      header
                                    )
                                  }
                                  value={
                                    editedRow !== undefined
                                      ? editedRow
                                      : data[header]
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      branchSelected,
                                      data.Categories,
                                      data.items,
                                      header,
                                      e.target.value
                                    )
                                  }
                                  onBlur={(e) => {
                                    handleChangeRowData(
                                      branchSelected,
                                      data.Categories,
                                      data.items
                                    );
                                    setHighlight({
                                      branch: null,
                                      category: null,
                                      item: null,
                                      header: null,
                                    });
                                  }}
                                  onKeyDown={(e) =>
                                    handleKeyDown(
                                      e,
                                      branchSelected,
                                      data.Categories,
                                      data.items,
                                      header
                                    )
                                  }
                                />
                              ) : (
                                <input
                                  style={{ border: "none" }}
                                  className={` w-[65px] ${
                                    data.items === ITEMS.UNDERINCOMEMODEL
                                      ? "bg-white"
                                      : "bg-[#fceec5]"
                                  } readonly `}
                                  onFocus={() =>
                                    handleFocus(
                                      branchSelected,
                                      data.Categories,
                                      data.items,
                                      header
                                    )
                                  }
                                  onClick={() =>
                                    handleFocus(
                                      branchSelected,
                                      data.Categories,
                                      data.items,
                                      header
                                    )
                                  }
                                  readOnly
                                  value={`${
                                    data.items === ITEMS.UNDERINCOMEMODEL
                                      ? data[header] ?? 0
                                      : formatAmount(data[header])
                                  }`}
                                />
                              )
                            ) : (
                              <div
                                onMouseDown={() =>
                                  handleHilight(
                                    branchSelected,
                                    data.Categories,
                                    data.items,
                                    header
                                  )
                                }
                              >
                                {nullValueRows
                                  ? null
                                  : level === "Branch"
                                  ? data.items === ITEMS.STAFFINGMANAGER
                                    ? parseFloat(calculatedRow[header]).toFixed(
                                        2
                                      )
                                    : data.items === ITEMS.INCOMEMODELPERC
                                    ? formatPercentValue(calculatedRow[header])
                                    : formatAmount(calculatedRow[header])
                                  : data.items === ITEMS.STAFFINGMANAGER
                                  ? parseFloat(data[header]).toFixed(2)
                                  : data.items === ITEMS.UNDERINCOMEMODEL
                                  ? data[header].toFixed(2)
                                  : data.items === ITEMS.INCOMEMODELPERC
                                  ? formatPercentValue(
                                      incomeModelPercentage.toString()
                                    )
                                  : formatAmount(data[header])}
                              </div> //Month wise calculated values
                            )}
                          </td>
                        );
                      }
                    )}

                    <td
                      className={`${
                        data.items === ITEMS.INCOMEMODEL
                          ? "bg-[#CCC0DA]"
                          : HIGHLIGHTED_PARTICULARS.includes(
                              data.items.trim()
                            ) && level !== "Branch"
                          ? "bg-tertiary text-white"
                          : "bg-white"
                      }`}
                      style={getHighlightStyle(
                        branchSelected,
                        data.Categories,
                        data.items,
                        "YTD"
                      )}
                      onMouseDown={() =>
                        handleHilight(
                          branchSelected,
                          data.Categories,
                          data.items,
                          "YTD"
                        )
                      }
                    >
                      {nullValueRows || nullValueCells
                        ? null
                        : level === "Branch"
                        ? INPUT_ROWS.includes(data.items.trim())
                          ? formatAmount(currentYTD)
                          : formatAmount(calculatedRow["YTD"])
                        : formatAmount(data["YTD"])}
                    </td>
                    <td
                      className={`  ${
                        HIGHLIGHTED_PARTICULARS.includes(data.items.trim()) &&
                        level !== "Branch"
                          ? "bg-tertiary text-white"
                          : "bg-white"
                      }`}
                      style={getHighlightStyle(
                        branchSelected,
                        data.Categories,
                        data.items,
                        "YTD %"
                      )}
                      onMouseDown={() =>
                        handleHilight(
                          branchSelected,
                          data.Categories,
                          data.items,
                          "YTD %"
                        )
                      }
                    >
                      {nullValueRows || nullValueCells
                        ? null
                        : level === "Branch"
                        ? INPUT_ROWS.includes(data.items.trim())
                          ? formatPercentValue(percentageYTD.toString())
                          : formatPercentValue(calculatedRow["YTD %"])
                        : formatPercentValue(data["YTD %"])}
                    </td>
                    <td
                      className={` ${
                        HIGHLIGHTED_PARTICULARS.includes(data.items.trim()) &&
                        level !== "Branch"
                          ? "bg-tertiary text-white"
                          : perOverLastYear > 0
                          ? "text-green-500"
                          : perOverLastYear < 0
                          ? "text-red-500"
                          : "bg-white"
                      }`}
                      style={getHighlightStyle(
                        branchSelected,
                        data.Categories,
                        data.items,
                        "Peroverlastyear"
                      )}
                      onMouseDown={() =>
                        handleHilight(
                          branchSelected,
                          data.Categories,
                          data.items,
                          "Peroverlastyear"
                        )
                      }
                    >
                      {perOverlyvalue}
                    </td>
                    <td
                      className={`${
                        HIGHLIGHTED_PARTICULARS.includes(data.items.trim()) &&
                        level !== "Branch"
                          ? "bg-tertiary text-white"
                          : "bg-white"
                      }`}
                    ></td>
                    {level === "Branch" &&
                      QUARTERLIST.map(
                        (q: {
                          quarter: string;
                          quarterPercentage: string;
                          name: string;
                        }) => {
                          return (
                            <>
                              <td
                                className={` ${
                                  data.QuarterflagQ === 1
                                    ? "bg-[#fceec5]"
                                    : HIGHLIGHTED_PARTICULARS.includes(
                                        data.items.trim()
                                      ) && level !== "Branch"
                                    ? "bg-tertiary text-white"
                                    : "bg-white"
                                }`}
                                style={getHighlightStyle(
                                  branchSelected,
                                  data.Categories,
                                  data.items,
                                  q.quarter
                                )}
                              >
                                {data.QuarterflagQ === 1 ? (
                                  (activeCell.branch === data.Branch &&
                                    activeCell.category === data.Categories &&
                                    activeCell.item === data.items &&
                                    activeCell.header?.includes(q.quarter)) ||
                                  (highlight.branch === data.Branch &&
                                    highlight.category === data.Categories &&
                                    highlight.item === data.items &&
                                    highlight.header === q.quarter) ? (
                                    <input
                                      type="number"
                                      style={{ border: "none" }}
                                      className=" w-[65px] bg-[#fceec5] "
                                      onFocus={() =>
                                        handleHilight(
                                          branchSelected,
                                          data.Categories,
                                          data.items,
                                          q.quarter
                                        )
                                      }
                                      value={
                                        editedData.find(
                                          (d: any) =>
                                            d.Branch === branchSelected &&
                                            d.items === data.items &&
                                            d.Categories === data.Categories
                                        )?.[q.quarter] !== undefined
                                          ? editedData.find(
                                              (d: any) =>
                                                d.Branch === branchSelected &&
                                                d.items === data.items &&
                                                d.Categories === data.Categories
                                            )?.[q.quarter]
                                          : data[q.quarter]
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          branchSelected,
                                          data.Categories,
                                          data.items,
                                          q.quarter,
                                          e.target.value
                                        )
                                      }
                                      onBlur={(e) => {
                                        handleChangeRowData(
                                          branchSelected,
                                          data.Categories,
                                          data.items
                                        );
                                        setHighlight({
                                          branch: null,
                                          category: null,
                                          item: null,
                                          header: null,
                                        });
                                      }}
                                      onKeyDown={(e) => {
                                        handleKeyDown(
                                          e,
                                          branchSelected,
                                          data.Categories,
                                          data.items,
                                          q.quarter
                                        );
                                      }}
                                    />
                                  ) : (
                                    <input
                                      style={{ border: "none" }}
                                      className="focus:outline-none w-[65px] bg-[#fceec5] "
                                      onFocus={() =>
                                        handleFocus(
                                          branchSelected,
                                          data.Categories,
                                          data.items,
                                          q.quarter
                                        )
                                      }
                                      onClick={() =>
                                        handleFocus(
                                          branchSelected,
                                          data.Categories,
                                          data.items,
                                          q.quarter
                                        )
                                      }
                                      readOnly
                                      value={`${formatAmount(data[q.quarter])}`}
                                    />
                                  )
                                ) : (
                                  <div
                                    onMouseDown={() =>
                                      handleHilight(
                                        branchSelected,
                                        data.Categories,
                                        data.items,
                                        q.quarter
                                      )
                                    }
                                  >
                                    {nullValueRows || nullValueCells
                                      ? null
                                      : level === "Branch"
                                      ? INPUT_ROWS.includes(data.items.trim())
                                        ? formatAmount(
                                            currentQuarters[
                                              q.name as keyof typeof currentQuarters
                                            ]
                                          )
                                        : formatAmount(calculatedRow[q.quarter])
                                      : formatAmount(data[q.quarter])}
                                  </div>
                                )}
                              </td>
                              <td
                                className={` ${
                                  data.Quarterflagperc === 1
                                    ? "bg-[#fceec5]"
                                    : HIGHLIGHTED_PARTICULARS.includes(
                                        data.items.trim()
                                      ) && level !== "Branch"
                                    ? "bg-tertiary text-white"
                                    : "bg-white"
                                }`}
                                style={getHighlightStyle(
                                  branchSelected,
                                  data.Categories,
                                  data.items,
                                  q.quarterPercentage
                                )}
                              >
                                {data.Quarterflagperc === 1 ? (
                                  (activeCell.branch === data.Branch &&
                                    activeCell.category === data.Categories &&
                                    activeCell.item === data.items &&
                                    activeCell.header?.includes(
                                      q.quarterPercentage
                                    )) ||
                                  (highlight.branch === data.Branch &&
                                    highlight.category === data.Categories &&
                                    highlight.item === data.items &&
                                    highlight.header ===
                                      q.quarterPercentage) ? (
                                    <input
                                      type="number"
                                      style={{ border: "none" }}
                                      className=" w-[65px] bg-[#fceec5] "
                                      onFocus={() =>
                                        handleHilight(
                                          branchSelected,
                                          data.Categories,
                                          data.items,
                                          q.quarterPercentage
                                        )
                                      }
                                      value={
                                        editedData.find(
                                          (d: any) =>
                                            d.Branch === branchSelected &&
                                            d.items === data.items &&
                                            d.Categories === data.Categories
                                        )?.[q.quarterPercentage] !== undefined
                                          ? editedData.find(
                                              (d: any) =>
                                                d.Branch === branchSelected &&
                                                d.items === data.items &&
                                                d.Categories === data.Categories
                                            )?.[q.quarterPercentage]
                                          : data[q.quarterPercentage]
                                      }
                                      onBlur={(e) => {
                                        handleChangeRowData(
                                          branchSelected,
                                          data.Categories,
                                          data.items
                                        );
                                        setHighlight({
                                          branch: null,
                                          category: null,
                                          item: null,
                                          header: null,
                                        });
                                      }}
                                      onKeyDown={(e) => {
                                        handleKeyDown(
                                          e,
                                          branchSelected,
                                          data.Categories,
                                          data.items,
                                          q.quarterPercentage
                                        );
                                      }}
                                      onChange={(e) =>
                                        handleInputChange(
                                          branchSelected,
                                          data.Categories,
                                          data.items,
                                          q.quarterPercentage,
                                          e.target.value
                                        )
                                      }
                                    />
                                  ) : (
                                    <input
                                      style={{ border: "none" }}
                                      className="focus:outline-none w-[65px] bg-[#fceec5] "
                                      onFocus={() =>
                                        handleFocus(
                                          branchSelected,
                                          data.Categories,
                                          data.items,
                                          q.quarterPercentage
                                        )
                                      }
                                      onClick={() =>
                                        handleFocus(
                                          branchSelected,
                                          data.Categories,
                                          data.items,
                                          q.quarterPercentage
                                        )
                                      }
                                      readOnly
                                      value={`${formatPercentValue(
                                        data[q.quarterPercentage]
                                      )}`}
                                    />
                                  )
                                ) : (
                                  <div
                                    onMouseDown={() =>
                                      handleHilight(
                                        branchSelected,
                                        data.Categories,
                                        data.items,
                                        q.quarterPercentage
                                      )
                                    }
                                  >
                                    {nullValueRows || nullValueCells
                                      ? null
                                      : level === "Branch"
                                      ? INPUT_ROWS.includes(data.items.trim())
                                        ? formatPercentValue(
                                            percentageQuarters[
                                              q.name as keyof typeof percentageQuarters
                                            ].toString()
                                          )
                                        : formatPercentValue(
                                            calculatedRow[q.quarterPercentage]
                                          )
                                      : formatPercentValue(
                                          data[q.quarterPercentage]
                                        )}
                                  </div>
                                )}
                              </td>
                              <td
                                className={`${
                                  HIGHLIGHTED_PARTICULARS.includes(
                                    data.items.trim()
                                  ) && level !== "Branch"
                                    ? "bg-tertiary text-white"
                                    : "bg-white"
                                }`}
                              ></td>
                            </>
                          );
                        }
                      )}
                  </tr>
                </>
              );
            })}{" "}
      </tbody>
    </Table>
  );
};

export default TableComponent;
