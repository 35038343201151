import { ActionIcon } from "@mantine/core";

const HomeButton = (props: any) => {
  const { adaOnly, resetFilters } = props;
  return (
    <div className="absolute top-1 left-0 flex items-center z-30">
      <ActionIcon onClick={resetFilters ? resetFilters : null}>
        <i className="material-icons" style={{ fontSize: "32px" }}>
          chevron_left
        </i>
      </ActionIcon>
      <ActionIcon>
        <a
          href={
            adaOnly
              ? "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/6aeb89df-fe24-4f1a-b4a1-2b32f6f9cb37/ReportSection?experience=power-bi"
              : "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionfdcec90ba28704f33c71?experience=power-bi"
          }
          target={"_blank"}
          rel="noreferrer"
        >
          <i
            className="text-slate-600 material-icons cursor-pointer "
            style={{ fontSize: "28px" }}
          >
            home
          </i>
        </a>{" "}
      </ActionIcon>
    </div>
  );
};

export default HomeButton;
