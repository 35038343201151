/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { utils, writeFile } from "xlsx";

export const handleExport = (
  headers: any,
  data: any,
  type: string,
  reportName: string
) => {
  const wb = utils.book_new();
  const ws = utils.json_to_sheet([]);
  utils.sheet_add_aoa(ws, [headers]);
  utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });

  data.forEach((row: any, rowIndex: number) => {
    const colStartIndex = 0;
    const colEndIndex = headers.length - 1;

    for (let colIndex = colStartIndex; colIndex <= colEndIndex; colIndex++) {
      const cellAddress = utils.encode_cell({ r: rowIndex + 1, c: colIndex });
      const cell = ws[cellAddress];

      if (cell && typeof cell.v === "number") {
        cell.z = "0.00";
        if (Number.isInteger(cell.v)) {
          cell.z = "0";
        }
      }
    }
  });
  utils.book_append_sheet(wb, ws, "Report");

  if (type === "xlsx") {
    writeFile(wb, reportName + ".xlsx");
  } else {
    writeFile(wb, reportName + ".csv");
  }
};
