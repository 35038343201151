import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { dottedLine, labelFormatter } from "../../../utils/helper/charts";
import { getWeekData } from "../companySummary/utils/weekdata";
import { useSelector } from "react-redux";
import { formatAmount } from "../../../utils/helper/cellFormatter";

const ChartArea = (props: any) => {
  const { colors, type, lineChartData } = props;
  const weeks = useSelector((state: any) => state.filterReducer.weekLabels);

  const tempChartValues = {
    [`${labelFormatter("Previous Year")}`]: [],
    [`${labelFormatter("Last Year")}`]: [],
    [`${labelFormatter("Current Year")}`]: [],
  };

  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      selection: {
        enabled: false,
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    colors: colors.map((c: any) => c.color),

    markers: {
      size: 1,
      strokeWidth: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    series: [],
    stroke: {
      dashArray: [0, 0, 0],
      width: 1.5,
      curve: "straight",
      colors: colors.map((c: any) => c.color),
    },
    legend: {
      show: false,
    },
    yaxis: [
      {
        labels: {
          style: {
            fontSize: "9px",
          },
          formatter: (v: any) => formatAmount(v),
        },
      },
    ],
    xaxis: {
      labels: {
        style: {
          fontSize: "7px",
        },
      },
      categories: weeks,
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: [0, 1, 2, 3],
      followCursor: !!props.isTopClientsReport,
      fixed: {
        enabled: true,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
    },
  });

  useEffect(() => {
    setChartOptions((prev: any) => ({
      ...prev,
      xaxis: {
        labels: {
          style: {
            fontSize: "7px",
          },
        },
        categories: weeks,
      },
      series: [
        ...Object.keys(tempChartValues).map((v, key) => {
          return {
            name: labelFormatter(v),
            data: [...getWeekData(lineChartData[v], type)],
          };
        }),
      ],
    }));
  }, [lineChartData, weeks]);

  return (
    <ReactApexChart
      options={chartOptions}
      series={chartOptions.series}
      type="line"
      height={"100%"}
    />
  );
};
const BonusReviewCharts = (props: {
  title: string;
  type: string;
  lineChartData: {};
}) => {
  const { title, type, lineChartData } = props;

  const colors = [
    { label: "Previous Year", color: "#1875B5" },
    { label: "Last Year", color: "#FF7F0E" },
    { label: "Current Year", color: "#29A229" },
  ];

  return (
    <div className="w-full">
      <div className="w-full flex justify-center">
        <div className="bg-tertiary text-white min-w-[15%] py-[0.2rem] px-4 rounded-md text-center text-sm mb-2">
          {title}
        </div>
      </div>
      <div className=" border-[1px] shadow-sm rounded-sm h-[40vh] bg-white p-2 text-[0.7rem]">
        <div className="chart-legend-area">
          <div className="colors-area">
            {colors?.map((v, key) => (
              <div className="color-box" key={key}>
                <div
                  className={
                    dottedLine(v.label)
                      ? `w-5 border-dotted border-y-2 border-x-0 border-[${v.color}]`
                      : "color-circle"
                  }
                  style={{ backgroundColor: `${v.color}` }}
                ></div>
                <div className={"color-name text-xs"}>
                  {labelFormatter(v.label)}
                </div>
              </div>
            ))}
          </div>
        </div>
        <ChartArea colors={colors} type={type} lineChartData={lineChartData} />
      </div>
    </div>
  );
};

export default BonusReviewCharts;
