import { useEffect, useState } from "react";
import {
  APIGetAllClerks,
  APIGetAllClients,
  APIGetAllPaymentTerms,
  APIGetArComments,
  APIGetClerkAssignemnts,
  APIGetDSO,
} from "../../../api/ar-aging";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../store/Filter/actions";
import { Loader } from "../../common/Loader";
import { Button, Select, Table } from "@mantine/core";
import { groupByKey } from "../../../utils/group";
import {
  formatAmount,
  sortByKey,
  sortByKeyDailyNumbers,
} from "../../../utils/helper/cellFormatter";
import { handleExport } from "../../../utils/helper/dataExport";
import { setSortBy } from "../../../store/ArAging/actions";
import { useMediaQuery } from "@mantine/hooks";
import { LABEL } from "../companySummary/utils/constants";

const ArClerkAssignment = () => {
  const dispatch = useDispatch();
  const [clerks, setClerks] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [clients, setClients] = useState([]);
  const [assignmentsData, setAssignmentsData] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  const [selectedClerk, setSelecetdClerk] = useState("All");
  const [selectedPaymentTerm, setSelecetdPaymentTerm] = useState("All");
  const [selectedClient, setSelectedClient] = useState("All");
  const [selectedCustomerId, setSelectedCustomerId] = useState("All");
  const [expanded, setExpanded] = useState<any>({});
  const [totalData, setTotalData] = useState<any>();
  const [dsoValue, setDsoValue] = useState<any>();
  const [isReverseSort, setIsReverseSort] = useState(false);
  const [sortedData, setSortedData] = useState<any>(assignmentsData);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>({
    key: "Customer",
    direction: "asc",
  });
  const largeScreen = useMediaQuery("(min-width: 768px)");

  const requestSort = (key: any) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    if (direction === "asc") {
      const sorted = sortByKeyDailyNumbers(assignmentsData, key, false);
      setSortedData(sorted);
    } else {
      const sorted = sortByKeyDailyNumbers(assignmentsData, key, true);
      setSortedData(sorted);
    }
    setSortConfig({ key, direction });
  };

  const headers = [
    { label: "Customer", value: "Customer" },
    { label: "Payment Terms", value: "PaymentTerm" },
    { label: "Credit Limit", value: "Creditlimit" },
    { label: "Sum of 30+", value: "30+" },
    { label: "Sum of 60+", value: "60+" },
    { label: "Sum of Zero To Thirty", value: "Zero To Thirty" },
    { label: "Sum of Thirty One To Sixty", value: "Thirty One To Sixty" },
    { label: "Sum of Sixty One To Ninety", value: "Sixty One To Ninety" },
    { label: "Sum of Over Ninety", value: "Over Ninety" },
    { label: "Sum of Balance", value: "Balance" },
  ];

  const SORTING_HEADERS = [
    "Customer",
    "Sum of 30+",
    "Sum of 60+",
    "Sum of Zero To Thirty",
    "Sum of Thirty One To Sixty",
    "Sum of Sixty One To Ninety",
    "Sum of Over Ninety",
    "Sum of Balance",
  ];

  useEffect(() => {
    loadFilters();
    loadAllData();
  }, []);

  const loadFilters = async () => {
    const promises = [
      APIGetAllClerks(),
      APIGetAllPaymentTerms(),
      APIGetAllClients(),
    ];
    const [clerks, paymentTerms, clients]: any = await Promise.all(promises);
    setClerks(clerks.data.map((c: any) => c.ARClerk ?? "N/A"));
    setPaymentTerms(paymentTerms.data.map((p: any) => p.PaymentTerm ?? "N/A"));
    setClients(clients.data.map((c: any) => c.customer ?? "N/A"));
  };

  const loadAllData = async () => {
    const promises = [
      APIGetClerkAssignemnts(
        selectedClerk,
        selectedPaymentTerm,
        selectedClient
      ),
      APIGetArComments(selectedClerk, selectedPaymentTerm, selectedClient),
      APIGetDSO(selectedClerk, selectedPaymentTerm, selectedClient),
    ];
    dispatch(setLoading(true));
    const [assignments, comments, dso]: any = await Promise.all(promises);
    const assignmentsGroup = groupByKey(assignments.data, "CustomerKey");
    const aData: any = sortByKey(
      Object.values(assignmentsGroup).map((value: any) => {
        return {
          ...value.reduce(
            (acc: any, curr: any) => {
              acc["InvoiceDate/Number"] = "Customer Total";
              acc["30+"] += parseFloat(curr["30+"]) ?? 0;
              acc["60+"] += parseFloat(curr["60+"]) ?? 0;
              acc["Zero To Thirty"] += parseFloat(curr["Zero To Thirty"]) ?? 0;
              acc["Thirty One To Sixty"] +=
                parseFloat(curr["Thirty One To Sixty"]) ?? 0;
              acc["Sixty One To Ninety"] +=
                parseFloat(curr["Sixty One To Ninety"]) ?? 0;
              acc["Over Ninety"] += parseFloat(curr["Over Ninety"]) ?? 0;
              acc.Balance += parseFloat(curr.Balance) ?? 0;
              return { ...curr, ...acc };
            },
            {
              "30+": 0,
              "60+": 0,
              "Zero To Thirty": 0,
              "Thirty One To Sixty": 0,
              "Sixty One To Ninety": 0,
              "Over Ninety": 0,
              Balance: 0,
            }
          ),
          data: value,
        };
      }),
      { type: "60+", isReverse: true }
    );

    setTotalData(
      aData.reduce(
        (acc: any, curr: any) => {
          acc.CustomerId = "";
          acc.Customer = "";
          acc["InvoiceDate/Number"] = "Grand Total";
          acc["30+"] += parseFloat(curr["30+"]) ?? 0;
          acc["60+"] += parseFloat(curr["60+"]) ?? 0;
          acc["Zero To Thirty"] += parseFloat(curr["Zero To Thirty"]) ?? 0;
          acc["Thirty One To Sixty"] +=
            parseFloat(curr["Thirty One To Sixty"]) ?? 0;
          acc["Sixty One To Ninety"] +=
            parseFloat(curr["Sixty One To Ninety"]) ?? 0;
          acc["Over Ninety"] += parseFloat(curr["Over Ninety"]) ?? 0;
          acc.Balance += parseFloat(curr.Balance) ?? 0;
          acc.Creditlimit += parseFloat(curr.Creditlimit) ?? 0;
          return { ...curr, ...acc };
        },
        {
          "30+": 0,
          "60+": 0,
          "Zero To Thirty": 0,
          "Thirty One To Sixty": 0,
          "Sixty One To Ninety": 0,
          "Over Ninety": 0,
          Balance: 0,
          Creditlimit: 0,
        }
      )
    );
    setAssignmentsData(aData);
    setSortedData(aData);
    setCommentsData(comments.data);
    setDsoValue(dso.data[0].DSO);
    dispatch(setLoading(false));
  };

  const loadCommentsData = async () => {
    dispatch(setLoading(true));
    const comments = await APIGetArComments(
      selectedClerk,
      selectedPaymentTerm,
      selectedClient
    );
    setCommentsData(comments.data);
    dispatch(setLoading(false));
  };

  useEffect(() => {
    loadAllData();
    loadCommentsData();
  }, [selectedClerk, selectedPaymentTerm, selectedClient]);

  // useEffect(() => {
  //   loadCommentsData();
  // }, [selectedCustomerId]);

  const changeCollapseState = (customerId: any) => {
    const expand = { ...expanded, [customerId]: !expanded[customerId] };
    setExpanded(expand);
  };

  const selectCustomer = (customerId: any) => {
    setSelectedCustomerId(
      selectedCustomerId === customerId ? "All" : customerId
    );
  };

  const rows = sortedData.map((assignment: any) => {
    return (
      <>
        <tr
          className={
            selectedCustomerId === assignment.CustomerId ? "bg-gray-300" : ""
          }
        >
          <th>
            <div className="flex items-center font-semibold cursor-pointer">
              <i
                className="material-icons cursor-pointer"
                onClick={() => changeCollapseState(assignment.CustomerId)}
              >
                {expanded[assignment.CustomerId]
                  ? "keyboard_arrow_down"
                  : "keyboard_arrow_right"}
              </i>
              <div className="flex justify-between w-full">
                <span onClick={() => selectCustomer(assignment.CustomerId)}>
                  {assignment.Customer}
                </span>
                {assignment.Label === LABEL.VMS && (
                  <i className="material-icons text-[1rem] ">star_border</i>
                )}
              </div>
            </div>
          </th>
          <th className="font-semibold" style={{ textAlign: "right" }}>
            {assignment["PaymentTerm"]}
          </th>
          <th className="font-semibold" style={{ textAlign: "right" }}>
            {formatAmount(assignment["Creditlimit"], false)}
          </th>
          <th className="font-semibold" style={{ textAlign: "right" }}>
            {formatAmount(assignment["30+"], false)}
          </th>
          <th className="font-semibold" style={{ textAlign: "right" }}>
            {formatAmount(assignment["60+"], false)}
          </th>
          <th className="font-semibold" style={{ textAlign: "right" }}>
            {formatAmount(assignment["Zero To Thirty"], false)}
          </th>
          <th className="font-semibold" style={{ textAlign: "right" }}>
            {formatAmount(assignment["Thirty One To Sixty"], false)}
          </th>
          <th className="font-semibold" style={{ textAlign: "right" }}>
            {formatAmount(assignment["Sixty One To Ninety"], false)}
          </th>
          <th className="font-semibold" style={{ textAlign: "right" }}>
            {formatAmount(assignment["Over Ninety"], false)}
          </th>
          <th className="font-semibold" style={{ textAlign: "right" }}>
            {formatAmount(assignment["Balance"], false)}
          </th>
        </tr>
        {expanded[assignment.CustomerId] &&
          assignment.data.map((data: any) => {
            return (
              <tr>
                <td className="" style={{ paddingLeft: "3rem" }}>
                  {data["InvoiceDate/Number"]}
                </td>
                <td className="text-right"></td>
                <td className="text-right"></td>
                <td className="text-right">
                  {formatAmount(data["30+"], false)}
                </td>
                <td className="text-right">
                  {formatAmount(data["60+"], false)}
                </td>
                <td className="text-right">
                  {formatAmount(data["Zero To Thirty"], false)}
                </td>
                <td className="text-right">
                  {formatAmount(data["Thirty One To Sixty"], false)}
                </td>
                <td className="text-right">
                  {formatAmount(data["Sixty One To Ninety"], false)}
                </td>
                <td className="text-right">
                  {formatAmount(data["Over Ninety"], false)}
                </td>
                <td className="text-right">
                  {formatAmount(data["Balance"], false)}
                </td>
              </tr>
            );
          })}
      </>
    );
  });

  const exportData = () => {
    let excelData: any = [];
    assignmentsData.forEach((assignment: any) => {
      const subData = assignment.data;
      delete assignment.data;
      excelData.push(assignment);
      excelData = [...excelData, ...subData];
    });
    excelData.push(totalData);
    handleExport(
      Object.keys(excelData[0]),
      excelData,
      "xlsx",
      "AR_Clerk_Assignment"
    );
  };
  const sortVal = useSelector((state: any) => state.arAgingReducer.sortBy);
  const getIcon = (label: string) => {
    return sortConfig?.key !== label
      ? "remove"
      : sortConfig?.direction === "asc"
      ? "arrow_drop_down"
      : "arrow_drop_up";
  };
  const changeReverseOrder = (header: string) => {
    if (SORTING_HEADERS.includes(header)) {
      dispatch(
        setSortBy({
          type: header,
          isReverse: header === sortVal.type ? !sortVal.isReverse : false,
        })
      );
      if (header === "Customer") {
        setIsReverseSort(!isReverseSort);
      }
    }
  };

  return (
    <div className="h-[90dvh] text-[0.5rem]">
      <Loader />
      <div className="py-5 flex flex-col flex-1 gap-4 overflow-hidden">
        <div className="header px-5 flex flex-col sm:flex-row justify-between sm:items-center gap-5">
          <div className="w-full flex gap-5 items-center">
            <div className="w-1/3 sm:max-lg:w-1/5 lg:w-1/6">
              <Select
                size="xs"
                data={clerks}
                label="AR Clerk"
                value={selectedClerk}
                onChange={(v: any) => setSelecetdClerk(v)}
              />
            </div>
            <div className="w-1/3 sm:max-lg:w-1/5 lg:w-1/6">
              <Select
                size="xs"
                data={paymentTerms}
                label="Payment Term"
                value={selectedPaymentTerm}
                onChange={(v: any) => setSelecetdPaymentTerm(v)}
              />
            </div>
            <div className="w-1/3 sm:max-lg:w-1/5 lg:w-1/6">
              <Select
                size="xs"
                searchable
                data={["All", ...clients]}
                label="Client"
                value={selectedClient}
                onChange={(v: any) => setSelectedClient(v)}
              />
            </div>
          </div>
          <div className="flex items-center gap-4 ">
            <div className="flex items-center border-[1px] h-7 border-stone-400  gap-1 py-1 px-2 w-[100px]">
              {" "}
              <i className="material-icons text-[1.1rem]">star_border</i>
              <span className="text-[0.5rem] lg:text-[0.6rem] font-semibold">
                VMS Clients
              </span>
            </div>
            <div className="font-semibold text-[0.6] md:text-[0.7rem] ">
              DSO:
              <span className="border-[1px] px-2 py-[0.7rem] ml-1 rounded-md w-[100px]">
                {dsoValue}
              </span>
            </div>
            <Button onClick={exportData} className="">
              Export Data
            </Button>
          </div>
        </div>
        <div className="assignments h-[60dvh] overflow-y-auto px-2 lg:px-5 text-xs">
          <Table verticalSpacing="1" fontSize={largeScreen ? 12 : 10}>
            <thead className="sticky top-0 bg-white">
              <tr className="bg-blue-50">
                {headers.map((header, index: number) => (
                  <th
                    className={`${
                      SORTING_HEADERS.includes(header.label) ? "pointer" : ""
                    } ${header.label === "Customer" ? "p-1 w-1/6" : ""}
            `}
                    key={index}
                    onClick={() => {
                      changeReverseOrder(header.label);
                      requestSort(header.value);
                    }}
                  >
                    <div
                      className={`flex gap-1 items-center min-w-[100px] ${
                        header.label === "Customer"
                          ? "justify-start"
                          : "justify-end"
                      }`}
                    >
                      {SORTING_HEADERS.includes(header.label) && (
                        <i className="material-icons">
                          {getIcon(header.value)}
                        </i>
                      )}
                      {header.label}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{rows}</tbody>
            {totalData && (
              <tfoot className="sticky bottom-0 bg-white">
                <tr className="bg-blue-50">
                  <th className="p-1">Total</th>
                  <th style={{ textAlign: "right" }}></th>
                  <th style={{ textAlign: "right" }}></th>
                  <th style={{ textAlign: "right" }}>
                    {formatAmount(totalData["30+"] ?? 0, false)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {formatAmount(totalData["60+"] ?? 0, false)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {formatAmount(totalData["Zero To Thirty"] ?? 0, false)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {formatAmount(totalData["Thirty One To Sixty"] ?? 0, false)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {formatAmount(totalData["Sixty One To Ninety"] ?? 0, false)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {formatAmount(totalData["Over Ninety"] ?? 0, false)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {formatAmount(totalData["Balance"] ?? 0, false)}
                  </th>
                </tr>
              </tfoot>
            )}
          </Table>
        </div>
        <div className="comments flex-1  px-2 lg:px-5 max-h-[20vh] overflow-auto">
          <Table verticalSpacing="1" fontSize={largeScreen ? 12 : 10}>
            <thead className="sticky top-0 bg-white">
              <tr className="border-b-2">
                <th>Customer</th>
                <th>Date</th>
                <th>Comment</th>
              </tr>
            </thead>
            {commentsData.length > 0 ? (
              <tbody>
                {commentsData.map((comment: any) => {
                  return (
                    <tr>
                      <td className=" min-w-[240px]">
                        <div className="flex items-center justify-between mr-1">
                          <span>{comment.Customer}</span>
                          {comment.Label === LABEL.VMS && (
                            <i className="material-icons text-[1rem]">
                              star_border
                            </i>
                          )}
                        </div>
                      </td>
                      <td className="min-w-[120px]">{comment.Date}</td>
                      <td>{comment.Comment}</td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <div className="py-5 text-sm">No Comments data available</div>
            )}
          </Table>
        </div>
      </div>
    </div>
  );
};
export default ArClerkAssignment;
