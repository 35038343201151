/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import { Button, SegmentedControl, Select, Switch } from "@mantine/core";
import {
  APIGetADABranches,
  APIGetCompanySummaryRegionsWithoutVirtual,
} from "../../../api/company-summary";
import { groupByKey } from "../../../utils/group";
import {
  APIFetchAcquisitionDataOptions,
  APIFetchPreviousRolling12MonthsOptions,
  APIFetchPreviousRolling24MonthsOptions,
  APIFetchPreviousRolling3MonthsOptions,
  APIFetchRolling12MonthsOptions,
  APIFetchRolling3MonthsOptions,
} from "../../../api/top-clients";
import {
  APIGetCalendarQuarters,
  APIGetCalendarMonths,
  APIGetCalendarWeeks,
  APIGetCalendarWeeksLastYear,
} from "../../../api/filter";

export const TopClientsFilter = ({
  selectedLevel,
  selectedYear,
  setSelectedYear,
  selectedWeek,
  setSelectedWeek,
  selectedMonth,
  setSelectedMonth,
  selectedQuarter,
  setSelectedQuarter,
  selectedArea,
  setSelectedArea,
  selectedSubarea,
  setSelectedSubarea,
  selectedRegion,
  setSelectedRegion,
  selectedDistrict,
  setSelectedDistrict,
  selectedBranch,
  setSelectedBranch,
  selectedTimeline,
  setSelectedTimeline,
  selectedDatamode,
  setSelectedDatamode,
  selectedYearMonth,
  setSelectedYearMonth,
  exportData,
  wip,
  acquisitionClient,
  setAcquisitionClient,
  selectedReferenceNote,
  setSelectedReferenceNote,
}: any) => {
  const [yearMonthOptions, setYearMonthOptions] = useState<any>({});
  const [areaList, setAreaList] = useState(["All"]);
  const [subareaList, setSubAreaList] = useState(["All"]);
  const [regionList, setRegionList] = useState(["All"]);
  const [districtList, setDistrictList] = useState(["All"]);
  const [branchList, setBranchList] = useState(["All"]);
  const [heirarchy, setHierarchy] = useState<any>({});
  const [location, setLocation] = useState<any>([]);
  const [defaultLocation, setDefaultLocation] = useState<any>({
    areas: [],
    subareas: [],
    regions: [],
    districts: [],
    branches: [],
  });
  const [weeksData, setWeeksData] = useState<any>([]);
  const [weeksDataLastYear, setWeeksDataLastYear] = useState<any>([]);
  const [monthsData, setMonthsData] = useState([]);
  const [quartersData, setQuartersData] = useState([]);
  const [yearData, setYearData] = useState<string[]>([]);
  const [weekOptions, setWeekOptions] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);
  const [quarterOptions, setQuarterOptions] = useState([]);
  const [showDistrictSlicer, setShowDistrictSlicer] = useState(true);
  const timelineOptions = [
    { value: "Weekly", label: "Weekly" },
    { value: "Rolling12Months", label: "Rolling 12 Months" },
    { value: "YTD", label: "This Year YTD" },
    { value: "Rolling30Days", label: "Rolling 30 Days" },
    { value: "Rolling90Days", label: "Rolling 90 Days" },
    { value: "Rolling3Months", label: "Rolling 3 Months" },
    { value: "Monthly", label: "Monthly" },
    { value: "PreviousRolling12Months", label: "Last Year Rolling 12 Months" },
    { value: "YTDLastYear", label: "Last Year YTD" },
    { value: "LastYearRolling30Days", label: "Last Year Rolling 30 Days" },
    { value: "LastYearRolling90Days", label: "Last Year Rolling 90 Days" },
    { value: "PreviousRolling3Months", label: "Last Year Rolling 3 Months" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "PreviousRolling24Months", label: "Last Year Rolling 24 Months" },
  ];
  const [acquisitionClientOptions, setAcquisitionClientOptions] = useState<any>(
    []
  );
  // const fetchWeeksData = () => {
  //   if (
  //     selectedTimeline === "YTDLastYear" ||
  //     selectedTimeline === "LastYearRolling30Days" ||
  //     selectedTimeline === "LastYearRolling90Days"
  //   ) {
  //     APIGetCalendarWeeksLastYear().then((res) => {
  //       setWeeksData(res.data);
  //       setYearData(Object.keys(groupByKey(res.data, "CalendarYear")));
  //       // setSelectedYear(res.data[res.data.length - 1]?.CalendarYear);
  //     });
  //   } else {
  //     APIGetCalendarWeeks().then((res) => {
  //       setWeeksData(res.data);
  //       setYearData(Object.keys(groupByKey(res.data, "CalendarYear")));
  //       // setSelectedYear(res.data[res.data.length - 1]?.CalendarYear);
  //     });
  //   }
  // };
  useEffect(() => {
    APIGetCalendarWeeks().then((res) => {
      setWeeksData(res.data);
    });
    APIGetCalendarWeeksLastYear().then((res) => {
      setWeeksDataLastYear(res.data);
    });
    APIGetCalendarMonths().then((res) => {
      setMonthsData(res.data);
    });
    APIGetCalendarQuarters().then((res) => {
      setQuartersData(res.data);
    });
    fetchCalendarWeeks();
  }, []);

  useEffect(() => {
    fetchCalendarWeeks();
  }, [selectedYear]);

  useEffect(() => {
    if (acquisitionClient === true) {
      APIFetchAcquisitionDataOptions().then((res) => {
        // setAcquisitionClientOptions(res.data);
        const data: any = res.data
          .filter((option: any) => option.referencenote != null)
          .map((option: any) => option.referencenote);
        setAcquisitionClientOptions(["All", ...data]);
      });
    }
  }, [acquisitionClient]);

  const fetchCalendarWeeks = async () => {
    const data = await Promise.all([
      APIFetchRolling3MonthsOptions(),
      APIFetchRolling12MonthsOptions(),
      APIFetchPreviousRolling3MonthsOptions(),
      APIFetchPreviousRolling12MonthsOptions(),
      APIFetchPreviousRolling24MonthsOptions(),
    ]);
    const allOptions = {
      Rolling3Months: data[0].data.map((d: any) => {
        return {
          label: d.RollingMonths,
          value: { year: d.CalendarYear, month: d.MonthNumOfYear },
        };
      }),
      Rolling12Months: data[1].data.map((d: any) => {
        return {
          label: d.RollingMonths,
          value: { year: d.CalendarYear, month: d.MonthNumOfYear },
        };
      }),
      PreviousRolling3Months: data[2].data.map((d: any) => {
        return {
          label: d.RollingMonths,
          value: { year: d.CalendarYear, month: d.MonthNumOfYear },
        };
      }),
      PreviousRolling12Months: data[3].data.map((d: any) => {
        return {
          label: d.RollingMonths,
          value: { year: d.CalendarYear, month: d.MonthNumOfYear },
        };
      }),
      PreviousRolling24Months: data[4].data.map((d: any) => {
        return {
          label: d.RollingMonths,
          value: { year: d.CalendarYear, month: d.MonthNumOfYear },
        };
      }),
    };
    setYearMonthOptions(allOptions);
    setSelectedYearMonth({
      Rolling3Months: allOptions.Rolling3Months[0].value,
      Rolling12Months: allOptions.Rolling12Months[0].value,
      PreviousRolling3Months: allOptions.PreviousRolling3Months[0].value,
      PreviousRolling12Months: allOptions.PreviousRolling12Months[0].value,
      PreviousRolling24Months: allOptions.PreviousRolling24Months[0].value,
    });
  };

  useEffect(() => {
    if (
      selectedTimeline === "Weekly" ||
      selectedTimeline === "Rolling30Days" ||
      selectedTimeline === "Rolling90Days" ||
      selectedTimeline === "YTD"
    ) {
      const weeks: any = weeksData
        .filter((w: any) => w.CalendarYear === parseInt(selectedYear))
        .map((a: any) => {
          return a["Week and Date"];
        });
      setWeekOptions(weeks);
      setSelectedWeek(weeks[weeks.length - 1]);
      setYearData(Object.keys(groupByKey(weeksData, "CalendarYear")));
    }
    if (
      selectedTimeline === "YTDLastYear" ||
      selectedTimeline === "LastYearRolling30Days" ||
      selectedTimeline === "LastYearRolling90Days"
    ) {
      const weeks: any = weeksDataLastYear
        .filter((w: any) => w.CalendarYear === parseInt(selectedYear))
        .map((a: any) => {
          return a["Week and Date"];
        });
      setWeekOptions(weeks);
      setSelectedWeek(weeks[weeks.length - 1]);
      setYearData(Object.keys(groupByKey(weeksData, "CalendarYear")));
    }
    if (selectedTimeline === "Monthly") {
      const months: any = monthsData
        .filter((m: any) => m.CalendarYear === parseInt(selectedYear ?? 2024))
        .map((a: any) => {
          return { label: a.Monthname, value: a.MonthNumOfYear };
        });
      setMonthOptions(months);
      setSelectedMonth(months[months.length - 1].value);
    }
    if (selectedTimeline === "Quarterly") {
      const quarters: any = quartersData
        .filter((q: any) => q.CalendarYear === parseInt(selectedYear ?? 2024))
        .map((a: any) => {
          return { label: a.QuarterName, value: a.CalendarQuarter };
        });
      setQuarterOptions(quarters);
      setSelectedQuarter(quarters[quarters.length - 1].value);
    }
  }, [selectedTimeline, weeksData, monthsData, quartersData, selectedYear]);

  const setAreas = (areas: any) => {
    setAreaList(["All", ...areas]);
  };
  const setSubAreas = (subareas: any) => {
    setSubAreaList(["All", ...subareas]);
  };
  const setRegions = (regions: any) => {
    setRegionList(["All", ...regions]);
  };
  const setDistricts = (districts: any) => {
    setDistrictList(["All", ...districts]);
  };
  const setBranches = (branches: any) => {
    setBranchList([
      "All",
      ...branches.sort((a: any, b: any) => {
        return a > b ? 1 : a < b ? -1 : 0;
      }),
    ]);
  };

  const fetchRegionsData = async () => {
    const res =
      selectedLevel === "Company"
        ? await APIGetCompanySummaryRegionsWithoutVirtual()
        : await APIGetADABranches();
    setLocation(res.data);
    const data = res.data.map((v: any) => ({
      ...v,
      Areaname:
        v["Areaname"] && v["Areaname"] !== "" ? v["Areaname"] : "Ungrouped",
      "Sub Area Name":
        v["Sub Area Name"] && v["Sub Area Name"] !== ""
          ? v["Sub Area Name"]
          : "Ungrouped",
      Region: v["Region"] && v["Region"] !== "" ? v["Region"] : "Ungrouped",
      District:
        v["District"] && v["District"] !== "" ? v["District"] : "Ungrouped",
    }));
    defaultLocation.areas = Object.keys(groupByKey(data, "Areaname")).filter(
      (v) => v !== "Ungrouped"
    );
    defaultLocation.subareas = Object.keys(
      groupByKey(data, "Sub Area Name")
    ).filter((v) => v !== "Ungrouped");
    defaultLocation.regions = Object.keys(groupByKey(data, "Region")).filter(
      (v) => v !== "Ungrouped"
    );
    defaultLocation.districts = Object.keys(
      groupByKey(data, "District")
    ).filter((v) => v !== "Ungrouped");
    defaultLocation.branches = Object.keys(groupByKey(data, "Branch"));
    let locationHier: any = {};
    const topHier = groupByKey(data, "Areaname");
    Object.keys(topHier).forEach((v1: any) => {
      let temp = groupByKey(topHier[v1], "Region");
      let subHier = {};
      for (let v2 in temp) {
        // let reg = groupByKey(temp[v2], "Location");
        // let subSubHier = {};
        // for (let v3 in reg) {
        //   subSubHier = {
        //     ...subSubHier,
        //     [v3]: Object.values(reg[v3]).map((v: any) => v.branch),
        //   };
        // }
        subHier = {
          ...subHier,
          [v2]: Object.values(temp[v2]).map((v: any) => v.Branch),
        };
      }
      locationHier = {
        ...locationHier,
        [v1]: subHier,
      };
    });

    setHierarchy(locationHier);
    const a: any = [],
      r: any = [],
      b: any = [];
    Object.keys(heirarchy).map((area: any) => {
      if (area && area !== "Ungrouped") a.push(area);
      Object.keys(heirarchy[area]).map((region: any) => {
        if (region && region !== "Ungrouped") r.push(region);
        heirarchy[area][region].map((branch: any) => {
          if (branch) b.push(branch);
        });
      });
    });
    setDefaultLocation(defaultLocation);
    setAreas(defaultLocation.areas);
    setSubAreas(defaultLocation.subareas);
    setRegions(defaultLocation.regions);
    setDistricts(defaultLocation.districts);
    setBranches(defaultLocation.branches);
  };
  useEffect(() => {
    fetchRegionsData();
  }, [selectedLevel]);

  const changeAreaSelection = (value: any) => {
    setSelectedArea(value);
    setSelectedSubarea("All");
    setSelectedRegion("All");
    setSelectedDistrict("All");
    setSelectedBranch("All");
    if (value === "All") {
      setSubAreas(defaultLocation.subareas);
      setRegions(defaultLocation.regions);
      setDistricts(defaultLocation.districts);
      setBranches(defaultLocation.branches);
    } else {
      const subareas: any = [];
      const regions: any = [];
      const districts: any = [];
      const branches: any = [];
      location.map((l: any) => {
        if (l.Areaname === value) {
          if (!subareas.includes(l["Sub Area Name"]))
            subareas.push(l["Sub Area Name"]);
          if (!regions.includes(l.Region)) regions.push(l.Region);
          if (!districts.includes(l.District)) districts.push(l.District);
          branches.push(l.Branch);
        }
      });
      setSubAreas(subareas.filter((d: any) => !!d));
      setRegions(regions.filter((d: any) => !!d));
      setDistricts(districts.filter((d: any) => !!d));
      setBranches(branches.filter((d: any) => !!d));
    }
  };

  const changeSubAreaSelection = (value: any) => {
    setSelectedSubarea(value);
    setSelectedRegion("All");
    setSelectedDistrict("All");
    setSelectedBranch("All");
    if (value === "All") {
      setSelectedArea("All");
      setRegions(defaultLocation.regions);
      setDistricts(defaultLocation.districts);
      setBranches(defaultLocation.branches);
    } else {
      const regions: any = [];
      const districts: any = [];
      const branches: any = [];
      location.map((l: any) => {
        if (l["Sub Area Name"] === value) {
          if (!regions.includes(l.Region)) regions.push(l.Region);
          if (!districts.includes(l.District)) districts.push(l.District);
          branches.push(l.Branch);
        }
      });
      setRegions(regions.filter((d: any) => !!d));
      setDistricts(districts.filter((d: any) => !!d));
      setBranches(branches.filter((d: any) => !!d));
    }
  };

  const changeRegionSelection = (value: any) => {
    setSelectedRegion(value);
    setSelectedDistrict("All");
    setSelectedBranch("All");
    if (value === "All") {
      setSelectedArea("All");
      setSelectedSubarea("All");
      const r: any = [],
        b: any = [];

      Object.keys(heirarchy[selectedArea]).map((region: any) => {
        if (region && region !== "Ungrouped") r.push(region);
        heirarchy[selectedArea][region].map((branch: any) => {
          if (branch) b.push(branch);
        });
      });
      setRegions(r);
      setBranches(b);
      setDistricts(defaultLocation.districts);
    } else {
      const selectedLocation = location.find((l: any) => l.Region === value);
      const districts: any = [];
      const branches: any = [];
      location.map((l: any) => {
        if (l.Region === value) {
          if (!districts.includes(l.District)) districts.push(l.District);
          branches.push(l.Branch);
        }
      });
      setSelectedArea(selectedLocation?.Areaname ?? "All");
      setSelectedSubarea(selectedLocation["Sub Area Name"] ?? "All");
      setBranches(branches.filter((d: any) => !!d));
      setDistricts(districts.filter((d: any) => !!d));
      districts && districts.filter((d: any) => d !== null).length !== 0
        ? setShowDistrictSlicer(true)
        : setShowDistrictSlicer(false);
    }
  };

  const changeDistrictSelection = (value: any) => {
    setSelectedDistrict(value);
    setSelectedBranch("All");
    if (value === "All") {
      setSelectedArea("All");
      setSelectedSubarea("All");
      setSelectedRegion("All");
      setBranches(defaultLocation.branches);
    } else {
      const selectedLocation = location.find((l: any) => l.District === value);
      const branches: any = [];
      location.map((l: any) => {
        if (l.District === value) {
          branches.push(l.Branch);
        }
      });
      setSelectedArea(selectedLocation?.Areaname ?? "All");
      setSelectedSubarea(selectedLocation["Sub Area Name"] ?? "All");
      setSelectedRegion(selectedLocation?.Region ?? "All");
      setBranches(branches.filter((d: any) => !!d));
    }
  };

  const changeBranchSelection = (value: any) => {
    if (value !== "All") {
      const selectedLocation = location.find((l: any) => l.Branch === value);
      setSelectedArea(
        selectedLocation?.Areaname && selectedLocation.Areaname !== ""
          ? selectedLocation?.Areaname
          : "All"
      );
      setSelectedSubarea(selectedLocation["Sub Area Name"] ?? "All");
      setSelectedRegion(selectedLocation?.Region ?? "All");
      setSelectedDistrict(selectedLocation?.District ?? "All");
      if (selectedLocation?.District && selectedLocation.District !== null) {
        setSelectedDistrict(selectedLocation?.District);
        setShowDistrictSlicer(true);
      } else if (selectedLocation.District === null) {
        setShowDistrictSlicer(false);
      } else {
        setSelectedDistrict("All");
        setShowDistrictSlicer(true);
      }
    } else {
      setSelectedArea("All");
      setSelectedSubarea("All");
      setSelectedRegion("All");
      setSelectedDistrict("All");
    }
    setSelectedBranch(value);
  };

  const setYearMonth = (e: any) => {
    setSelectedYearMonth({ ...selectedYearMonth, [selectedTimeline]: e });
  };

  return (
    <div className="flex items-center gap-1 justify-between">
      <div className="flex flex-wrap items-end gap-1 max-w-[40%]">
        {selectedLevel === "Company" && (
          <>
            <Select
              className="w-24 sm:w-24"
              size="xs"
              label="Area"
              data={areaList}
              value={selectedArea}
              onChange={changeAreaSelection}
            />
            <Select
              className="w-24 sm:w-24"
              size="xs"
              label="Subarea"
              data={subareaList}
              value={selectedSubarea}
              onChange={changeSubAreaSelection}
            />
            <Select
              className="w-24 sm:w-32"
              size="xs"
              label="Region"
              data={regionList}
              value={selectedRegion}
              onChange={changeRegionSelection}
            />
            {showDistrictSlicer && (
              <Select
                className="w-24 sm:w-32"
                size="xs"
                label="Area Branch"
                data={districtList}
                value={selectedDistrict}
                onChange={changeDistrictSelection}
              />
            )}
          </>
        )}

        <Select
          className="w-24 sm:w-32"
          size="xs"
          label="Branch"
          data={branchList}
          value={selectedBranch}
          onChange={changeBranchSelection}
        />
        {(selectedTimeline === "Weekly" ||
          selectedTimeline === "Monthly" ||
          selectedTimeline === "Quarterly" ||
          selectedTimeline === "Rolling30Days" ||
          selectedTimeline === "Rolling90Days" ||
          selectedTimeline === "YTD" ||
          selectedTimeline === "YTDLastYear" ||
          selectedTimeline === "LastYearRolling30Days" ||
          selectedTimeline === "LastYearRolling90Days") && (
          <Select
            className="w-22 sm:w-28"
            size="xs"
            data={yearData}
            label="Year"
            value={selectedYear}
            onChange={setSelectedYear}
          />
        )}
        {selectedTimeline === "Weekly" ||
        selectedTimeline === "Rolling30Days" ||
        selectedTimeline === "Rolling90Days" ||
        selectedTimeline === "YTD" ||
        selectedTimeline === "YTDLastYear" ||
        selectedTimeline === "LastYearRolling30Days" ||
        selectedTimeline === "LastYearRolling90Days" ? (
          <Select
            className="sm:w-40 w-36"
            size="xs"
            label="Selected Week"
            data={weekOptions}
            value={selectedWeek}
            onChange={setSelectedWeek}
          />
        ) : selectedTimeline === "Monthly" ? (
          <Select
            className="w-24 sm:w-32"
            size="xs"
            label="Selected Month"
            data={monthOptions}
            value={selectedMonth}
            onChange={setSelectedMonth}
          />
        ) : selectedTimeline === "Quarterly" ? (
          <Select
            className="w-24 sm:w-32"
            size="xs"
            label="Selected Quarter"
            data={quarterOptions}
            value={selectedQuarter}
            onChange={setSelectedQuarter}
          />
        ) : (
          <Select
            className="w-40 sm:w-64"
            size="xs"
            label="Selected Year Month"
            data={yearMonthOptions?.[selectedTimeline]}
            value={selectedYearMonth[selectedTimeline]}
            onChange={setYearMonth}
          />
        )}
        {acquisitionClient && (
          <Select
            className="w-40"
            size="xs"
            label="Acquisition Clients"
            data={acquisitionClientOptions}
            value={selectedReferenceNote}
            onChange={setSelectedReferenceNote}
          />
        )}
      </div>
      <div className="flex flex-col sm:flex-row items-end gap-4">
        {/* <SegmentedControl
          size="xs"
          color="blue"
          value={selectedTimeline}
          onChange={setSelectedTimeline}
          data={timelineOptions}
        ></SegmentedControl> */}
        {wip && (
          <div className="text-xs font-semibold rounded-md flex gap-3 justify-center items-end">
            {acquisitionClient && (
              <Select
                size="xs"
                label="Acquisition Clients"
                data={acquisitionClientOptions}
                value={selectedReferenceNote}
                onChange={setSelectedReferenceNote}
              />
            )}
            <Switch
              label="Acquisition Clients"
              className=" mb-2"
              size="xs"
              onChange={() => setAcquisitionClient(!acquisitionClient)}
            />
          </div>
        )}
        {/* <Select
          size="xs"
          label="Timeline"
          data={timelineOptions}
          value={selectedTimeline}
          onChange={setSelectedTimeline}
        /> */}

        <div className="grid grid-cols-4 lg:grid-cols-6 gap-1">
          {timelineOptions.map((options: any) => {
            return (
              <button
                className={`border-[1px] py-2 px-1 rounded-md shadow-sm text-[10px] ${
                  selectedTimeline === options.value
                    ? "bg-[#a8ccec] font-semibold"
                    : ""
                }`}
                onClick={() => {
                  setSelectedTimeline(options.value);
                  setSelectedWeek(null);
                }}
              >
                {options.label}
              </button>
            );
          })}
        </div>
        <div className="flex flex-col items-end gap-2">
          <div className="border-[1px] border-stone-300 flex items-center gap-1 py-1 px-2">
            {" "}
            <i className="material-icons text-[1.2rem]">star_border</i>
            <span className="text-[0.7rem] font-semibold">VMS Clients</span>
          </div>
          {selectedLevel === "Company" && (
            <Switch
              label="Acquisition Clients"
              className="font-semibold"
              size="sm"
              onChange={() => setAcquisitionClient(!acquisitionClient)}
              checked={acquisitionClient}
            />
          )}

          <div className="flex gap-2">
            <SegmentedControl
              size="xs"
              color="blue"
              value={selectedDatamode}
              onChange={setSelectedDatamode}
              data={[
                { label: "Gross Profit", value: "GrossProfit" },
                { label: "Revenue", value: "Revenue" },
              ]}
            ></SegmentedControl>
            <Button size="xs" onClick={exportData}>
              Export
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
