import HomeButton from "../components/common/HomeButton";
import { useEffect, useRef, useState } from "react";
import { TABLE_MONTH_HEADERS } from "../utils/mock-data/financial-report";
import GoalSettingTable from "../components/modules/goalSetting/GoalSettingTable";
import {
  APIBulkUpdate,
  APIChartHeaders,
  APIClearAll,
  APIGoalChartData,
  APIGoalSettingData,
  APIPublishGoalData,
} from "../api/goal-setting";
import { groupByKey, groupByMultipleKeys } from "../utils/group";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../store/Filter/actions";
import { Loader } from "../components/common/Loader";
import { Button, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { sortByKeyDailyNumbers } from "../utils/helper/cellFormatter";
import {
  hierarchy,
  ITEMS,
  NULL_ITEMS,
  QUARTER_HEADERS,
  quarterMonths,
  quarterPercKey,
  quarterSumKey,
  YTD_HEADERS,
} from "../components/modules/goalSetting/utils/constants";
import { notifications } from "@mantine/notifications";
import { Loader as MantineLoader } from "@mantine/core";
import GoalSettingChart from "../components/modules/goalSetting/GoalSettingChart";
import {
  getCalculatedPercentage,
  getMonthWiseSumOfData,
  getPerOverLastYear,
} from "../components/modules/goalSetting/utils/tableFormulae";
import {
  createHierarchy,
  formatChartData,
  formatExportData,
  getBranchAlignment,
} from "../components/modules/goalSetting/utils/formatter";

const GoalSetting = () => {
  const user = localStorage.getItem("username") ?? "";

  const [tableHeaders, setTableHeaders] = useState<any>([
    ...TABLE_MONTH_HEADERS(),
  ]);
  const [branchesList, setBranchesList] = useState<
    { name: string; location: string }[]
  >([]);
  const [branchSelected, setBranchSelected] = useState<{
    name: string;
    location: string;
  }>(branchesList[0] ?? { name: "All", location: "Company" });
  const [data, setData] = useState([]);
  const [filteredGoalData, setFilteredGoalData] = useState<{}[]>([]);
  const [aggregratedData, setAggregratedData] = useState<{}[]>([]);
  const [tableData, setTableData] = useState<{}[]>([]);
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state["filterReducer"].loading);
  const [opened, { open, close }] = useDisclosure(false);
  const [openedChart, { open: openChart, close: closeChart }] =
    useDisclosure(false);
  const [clearLoading, setClearLoading] = useState<boolean>(false);
  const [activeCell, setActiveCell] = useState<{
    branch: string | null;
    category: string | null;
    item: string | null;
    header: string[] | null;
  }>({ branch: null, category: null, item: null, header: null });
  const [highlight, setHighlight] = useState<{
    branch: string | null;
    category: string | null;
    item: string | null;
    header: string | null;
  }>({ branch: null, category: null, item: null, header: null });
  const [calcData, setCalcData] = useState<{}[]>([]);
  const [calcDataInputRows, setCalcDataInputRows] = useState<{}[]>([]);
  const [chartData, setChartData] = useState<{
    company: { [key: string]: {} };
    area: { [key: string]: {} };
    subarea: { [key: string]: {} };
    region: { [key: string]: {} };
    district: { [key: string]: {} };
    branch: { [key: string]: {} };
  }>();
  const [allChartHeaders, setAllChartHeaders] = useState<any>([]);
  const [branchChartHeader, setBranchChartHeader] = useState<{
    totalRev: number;
    goal: number;
    growthPerc: number;
  }>({ totalRev: 0, goal: 0, growthPerc: 0 });
  const [isSaving, setisSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isSavingOnBranchChange, setIsSavingOnBranchChange] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const saveAllTimeout = useRef<NodeJS.Timeout | null>(null);
  const calcDataRef = useRef(calcData);
  const calcDataInputRef = useRef(calcDataInputRows);

  useEffect(() => {
    document.title = "Ascend Custom Goal Setting Dashboard";
    getChartData();
    getChartHeaders();
  }, []);

  const getChartData = async () => {
    const res: any = await APIGoalChartData();
    setChartData({
      company: groupByKey(res.company, "calendaryear"),
      area: formatChartData(res.area, "AreaName"),
      subarea: formatChartData(res.subarea, "Sub Area name"),
      region: formatChartData(res.region, "region"),
      district: formatChartData(res.district, "district"),
      branch: formatChartData(res.branch, "branch"),
    });
  };

  const getChartHeaders = async () => {
    const res: any = await APIChartHeaders();
    setAllChartHeaders(res);
  };

  useEffect(() => {
    const totalRev =
      allChartHeaders.totalRevenue?.find(
        (d: any) => d.Branch === branchSelected.name
      )?.["2024 Total"] ?? 0;
    const goal =
      allChartHeaders.growthPerc?.find(
        (d: any) => d.Branch === branchSelected.name
      )?.["2025 Goal"] ?? 0;
    const growthPerc =
      allChartHeaders.growthPerc?.find(
        (d: any) => d.Branch === branchSelected.name
      )?.["Expected Growth %"] ?? 0;
    setBranchChartHeader({
      totalRev: totalRev,
      goal: goal,
      growthPerc: growthPerc,
    });
  }, [branchSelected, allChartHeaders]);

  const getYearChartData = () => {
    if (branchSelected.location === "Branch") {
      return chartData?.branch[branchSelected.name] ?? {};
    } else if (branchSelected.location === "District") {
      return chartData?.district[branchSelected.name] ?? {};
    } else if (branchSelected.location === "Region") {
      return chartData?.region[branchSelected.name] ?? {};
    } else if (branchSelected.location === "Sub Area name") {
      return chartData?.subarea[branchSelected.name] ?? {};
    } else if (branchSelected.location === "Areaname") {
      return chartData?.area[branchSelected.name] ?? {};
    } else if (branchSelected.location === "Company") {
      return chartData?.company ?? {};
    } else {
      return {};
    }
  };

  useEffect(() => {
    setTableHeaders(() => {
      return branchSelected.location === "Branch"
        ? [...TABLE_MONTH_HEADERS(), ...YTD_HEADERS, "", ...QUARTER_HEADERS]
        : [...TABLE_MONTH_HEADERS(), ...YTD_HEADERS];
    });
  }, [branchSelected.location]);

  useEffect(() => {
    fetchTableData();
  }, [branchSelected.name]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === "Escape") {
        setActiveCell({
          branch: null,
          category: null,
          item: null,
          header: null,
        });
        setHighlight({
          branch: null,
          category: null,
          item: null,
          header: null,
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const fetchTableData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await APIGoalSettingData();
      setData(response.data);
      const dataWithAggregrationFlag = response.data.filter(
        (d: any) => d.aggregationflag === 1
      ); // To filter branch data with values and not aggregrated data
      const transformedData = createHierarchy(
        dataWithAggregrationFlag,
        hierarchy
      );
      setBranchesList(transformedData);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleClearAll = async () => {
    const updatedGoalData = filteredGoalData.map((d: any) => {
      if (d.Branch === branchSelected.name && d.flags === 1) {
        TABLE_MONTH_HEADERS().map((month) => {
          if (d.items === ITEMS.UNDERINCOMEMODEL) {
            d[month] = d[month];
          } else {
            d[month] = 0;
          }
        });
      }
      if (d.Branch === branchSelected.name && d.QuarterflagQ === 1) {
        Object.values(quarterSumKey).map((quarter: any) => {
          d[quarter] = 0;
        });
      }
      if (d.Branch === branchSelected.name && d.Quarterflagperc === 1) {
        Object.values(quarterPercKey).map((percent: any) => {
          d[percent] = 0;
        });
      }
      return d;
    });
    // API call to set all keys to 0
    try {
      setClearLoading(true);
      const res: any = await APIClearAll(branchSelected.name);
      if (res.status === "success") {
        setFilteredGoalData(updatedGoalData);
      }
    } catch (err) {
      notifications.show({
        title: "Error",
        message: `Oops! Cannot clear data for ${branchSelected.name} `,
        color: "red",
      });
    } finally {
      setClearLoading(false);
    }
    close();
  };

  //Data aggregration for district/region/subarea/area/company
  const aggregrateData = (data: any) => {
    const result: any = {};
    for (const itemName in data) {
      const branches = data[itemName];
      const itemSums: any = {};
      const branchNames = new Set();
      const areaNames = new Set();
      const subAreaNames = new Set();
      const regions = new Set();
      const districts = new Set();

      branches.forEach((branchData: any) => {
        for (const key in branchData) {
          // Skip non-numeric fields and specific excluded fields
          if (["flags", "Quarterflagperc", "QuarterflagQ"].includes(key)) {
            continue;
          }
          if (key === "Branch") {
            branchNames.add(branchData[key]);
          } else if (key === "Areaname") {
            areaNames.add(branchData[key]);
          } else if (key === "Sub Area name") {
            subAreaNames.add(branchData[key]);
          } else if (key === "Region") {
            regions.add(branchData[key]);
          } else if (key === "District") {
            districts.add(branchData[key]);
          } else if (
            ["State", "Categories", "sorting", "items"].includes(key)
          ) {
            itemSums[key] = branchData[key];
          } else {
            itemSums[key] = (itemSums[key] || 0) + branchData[key];
          }
        }
      });
      //Filtering null values in array and convert single element array to string value
      const processSet = (set: Set<any>) => {
        const filteredArray = Array.from(set).filter((item) => item != null);
        return filteredArray.length === 1 ? filteredArray[0] : filteredArray;
      };

      itemSums["Branch"] = processSet(branchNames);
      itemSums["Areaname"] = processSet(areaNames);
      itemSums["Sub Area name"] = processSet(subAreaNames);
      itemSums["Region"] = processSet(regions);
      itemSums["District"] = processSet(districts);
      result[itemName] = itemSums;
    }
    return result;
  };

  //Set filtered data location wise
  useEffect(() => {
    if (data && data.length !== 0) {
      let filteredDataForAggregration;
      let aggrData;
      if (branchSelected.location === "Branch") {
        filteredDataForAggregration = data.filter(
          (d: any) => d.Branch === branchSelected.name
        );
        setFilteredGoalData(
          sortByKeyDailyNumbers(filteredDataForAggregration, "sorting", false)
        );
      } else {
        if (branchSelected.location === "District") {
          filteredDataForAggregration = data.filter(
            (d: any) =>
              d.District === branchSelected.name && d.aggregationflag === 1
          );
          aggrData = data.filter(
            (d: any) =>
              d.District === branchSelected.name && d.aggregationflag !== 1
          );
        } else if (branchSelected.location === "Region") {
          filteredDataForAggregration = data.filter(
            (d: any) =>
              d.Region === branchSelected.name && d.aggregationflag === 1
          );
          aggrData = data.filter(
            (d: any) =>
              d.Region === branchSelected.name && d.aggregationflag !== 1
          );
        } else if (branchSelected.location === "Sub Area name") {
          filteredDataForAggregration = data.filter(
            (d: any) =>
              d["Sub Area name"] === branchSelected.name &&
              d.aggregationflag === 1
          );
          aggrData = data.filter(
            (d: any) =>
              d["Sub Area name"] === branchSelected.name &&
              d.aggregationflag !== 1
          );
        } else if (branchSelected.location === "Areaname") {
          filteredDataForAggregration = data.filter(
            (d: any) =>
              d.Areaname === branchSelected.name && d.aggregationflag === 1
          );
          aggrData = data.filter(
            (d: any) =>
              d.Areaname === branchSelected.name && d.aggregationflag !== 1
          );
        } else {
          filteredDataForAggregration = data.filter(
            (d: any) => d.aggregationflag === 1
          );
          aggrData = data.filter(
            (d: any) =>
              d.aggregationflag !== 1 &&
              d.Areaname === null &&
              d["Sub Area name"] === null &&
              d.Region === null &&
              d.District === null &&
              d.Branch === null
          );
        }
        const groupedData = groupByMultipleKeys(filteredDataForAggregration, [
          "items",
          "Categories",
        ]);
        const result = aggregrateData(groupedData);
        //Sumed data by grouping for district/region/subarea/area/company
        setFilteredGoalData(
          sortByKeyDailyNumbers(Object.values(result), "sorting", false)
        );
        //Aggregrated data from api
        setAggregratedData(aggrData);
      }
    }
  }, [branchSelected, data]);

  useEffect(() => {
    calcDataRef.current = calcData;
  }, [calcData]);

  useEffect(() => {
    calcDataInputRef.current = calcDataInputRows;
  }, [calcDataInputRows]);

  const publishData = async () => {
    setIsPublishing(true);
    try {
      const res: any = await APIPublishGoalData();
      if (res.status === "success") {
        notifications.show({
          title: "",
          message: `Successfully published data `,
          color: "green",
        });
      }
    } catch (err) {
      notifications.show({
        title: "",
        message: `Error! Coundn't published data `,
        color: "red",
      });
    } finally {
      setIsPublishing(false);
    }
  };

  //Calculate sum and % of data along with income model % row
  const getSumCalculation = (data: any) => {
    //Calculate percentage for income model % from calculated oi and im values
    const incomeModelRow = data.find((d: any) => d.items === ITEMS.INCOMEMODEL);
    const operatingIncomeRow = data.find(
      (d: any) => d.items === ITEMS.OPERATINGINCOME
    );
    let incomeModelPercent: {} = {};
    const calculated = data.map((idata: any) => {
      if (idata.items === ITEMS.INCOMEMODELPERC) {
        incomeModelPercent = TABLE_MONTH_HEADERS().reduce(
          (acc: any, month: string) => {
            acc[month] =
              operatingIncomeRow[month] && incomeModelRow[month]
                ? (operatingIncomeRow[month] / incomeModelRow[month]) * 100
                : 0;
            return acc;
          },
          {}
        );
      }

      const ytdTotal = getMonthWiseSumOfData(idata, TABLE_MONTH_HEADERS());
      const currentQuarters = {
        Q1: getMonthWiseSumOfData(idata, quarterMonths.Q1),
        Q2: getMonthWiseSumOfData(idata, quarterMonths.Q2),
        Q3: getMonthWiseSumOfData(idata, quarterMonths.Q3),
        Q4: getMonthWiseSumOfData(idata, quarterMonths.Q4),
      };
      const netSalesRow = filteredGoalData.find(
        (data: any) => data.items.trim() === "Net Sales"
      );
      const lastYearGoalForCurrentRow: any = aggregratedData.find(
        (data: any) =>
          data.items === idata.items && data.Categories === idata.Categories
      );
      const lastYearGoal =
        branchSelected.location === "Branch"
          ? idata.lastyeargoal
          : lastYearGoalForCurrentRow?.lastyeargoal;

      const netSalesQuarters = {
        Q1: getMonthWiseSumOfData(netSalesRow, quarterMonths.Q1),
        Q2: getMonthWiseSumOfData(netSalesRow, quarterMonths.Q2),
        Q3: getMonthWiseSumOfData(netSalesRow, quarterMonths.Q3),
        Q4: getMonthWiseSumOfData(netSalesRow, quarterMonths.Q4),
      };
      const netSalesYTD = getMonthWiseSumOfData(
        netSalesRow,
        TABLE_MONTH_HEADERS()
      );

      const percentageQuarters = {
        Q1: getCalculatedPercentage(currentQuarters.Q1, netSalesQuarters.Q1),
        Q2: getCalculatedPercentage(currentQuarters.Q2, netSalesQuarters.Q2),
        Q3: getCalculatedPercentage(currentQuarters.Q3, netSalesQuarters.Q3),
        Q4: getCalculatedPercentage(currentQuarters.Q4, netSalesQuarters.Q4),
      };
      const percentageYTD = getCalculatedPercentage(ytdTotal, netSalesYTD);
      const percOverLastYear = getPerOverLastYear(ytdTotal, lastYearGoal);
      return {
        ...idata,
        ...(idata.items === ITEMS.INCOMEMODELPERC
          ? incomeModelPercent
          : undefined),
        YTD: ytdTotal,
        "YTD %": percentageYTD,
        lastyeargoal: lastYearGoal,
        Peroverlastyear: percOverLastYear,
        "1st Quarter": currentQuarters["Q1"],
        "1st %": percentageQuarters["Q1"],
        "2nd Quarter": currentQuarters["Q2"],
        "2nd %": percentageQuarters["Q2"],
        "3rd Quarter": currentQuarters["Q3"],
        "3rd %": percentageQuarters["Q3"],
        "4th Quarter": currentQuarters["Q4"],
        "4th %": percentageQuarters["Q4"],
      };
    });
    return calculated;
  };

  useEffect(() => {
    if (branchSelected.location === "Branch") {
      setTableData(filteredGoalData);
    } else {
      setTableData(getSumCalculation(filteredGoalData));
    }
  }, [branchSelected, filteredGoalData]);

  const handleSaveAll = async () => {
    try {
      let dataToSave;
      if (branchSelected.location === "Branch") {
        dataToSave = [...calcDataRef.current, ...calcDataInputRef.current];
      } else {
        dataToSave = getSumCalculation(filteredGoalData);
      }
      if (dataToSave && dataToSave.length !== 0) {
        setisSaving(true);
        const res: any = await APIBulkUpdate(dataToSave, branchSelected, user);

        if (res.status === "success") {
          notifications.show({
            title: "",
            message: `Successfully saved data for ${branchSelected.name}`,
            color: "green",
          });
          setActiveCell({
            branch: null,
            category: null,
            item: null,
            header: null,
          });
          setHighlight({
            branch: null,
            category: null,
            item: null,
            header: null,
          });
        }
      }
    } catch (error) {
      notifications.show({
        title: "",
        message: `Oops! Cannot save data for ${branchSelected.name}. Try again later.`,
        color: "red",
      });
    } finally {
      setisSaving(false);
    }
  };

  const handleBranchChange = async (branch: {
    name: string;
    location: string;
  }) => {
    if (saveAllTimeout.current) {
      clearTimeout(saveAllTimeout.current);
      saveAllTimeout.current = null;
    }
    if (!isSaving) {
      if (branchSelected.location === "Branch") {
        if (hasUnsavedChanges) {
          setIsSavingOnBranchChange(true);
          await handleSaveAll();
          setIsSavingOnBranchChange(false);
          setHasUnsavedChanges(false);
        }
      } else {
        setIsSavingOnBranchChange(true);
        await handleSaveAll();
        setIsSavingOnBranchChange(false);
      }
      return setBranchSelected(branch);
    }
  };

  const exportHeaders = ["", ...tableHeaders.filter((h: string) => h != "")];

  const getExportData = () => {
    let goalExportData: any[] = [];
    if (branchSelected.location === "Branch") {
      const combinedData = sortByKeyDailyNumbers(
        [...calcDataInputRows, ...calcData],
        "sorting"
      );
      goalExportData = formatExportData(combinedData, exportHeaders);
    } else {
      const sumCalculatedData = getSumCalculation(filteredGoalData);

      const gData = sumCalculatedData.filter(
        (d: any) => !NULL_ITEMS.includes(d.items)
      );
      goalExportData = formatExportData(gData, exportHeaders);
    }

    return goalExportData;
  };

  const exportData = async () => {
    const BASE_URL = process.env.REACT_APP_SAGE_API_URL;
    window.open(`${BASE_URL}goalsettingexport/customgoalexport`, "_blank");
  };

  return (
    <>
      {isSavingOnBranchChange && (
        <div className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-xs z-40 flex items-center justify-center">
          <div className="flex flex-col gap-2 items-center bg-white p-6 rounded shadow-xl text-center">
            <MantineLoader color="green" size={"md"} />
            <p className="">Saving Data. Please wait...</p>
          </div>
        </div>
      )}
      <main className="h-[100vh] px-2 overflow-auto  flex flex-col gap-2 lg:gap-8 pb-2">
        <HomeButton adaOnly={false} />
        <Loader />
        <header className="flex flex-col  flex-1 pt-1 ">
          <div className="text-md font-semibold ml-14  ">
            Ascend Custom Goal Setting Dashboard
          </div>
          <div className="flex flex-col sm:flex-row justify-center gap-2">
            <div className=" w-0 sm:w-[25%]"></div>
            <div className="mx-auto flex flex-col gap-1 items-center ">
              <div className="text-sm w-[200px] font-medium p-1 bg-tertiary text-center text-white">
                {branchSelected.name ?? "Ascend Staffing"}
              </div>
            </div>
            <div className="flex w-full lg:w-[25%] justify-end items-center  gap-3">
              {isSaving ? (
                <div className="flex gap-2 items-center text-green-600 ">
                  {" "}
                  <MantineLoader color="green" size={"xs"} />
                  <span className="text-[0.8rem]">Saving Data...</span>
                </div>
              ) : (
                <div></div>
              )}

              <Button variant="outline" size="xs" onClick={openChart}>
                <i className="material-icons pr-1">show_chart</i>Show Chart
              </Button>
              <Button
                variant="filled"
                size="xs"
                color="green.7"
                onClick={publishData}
              >
                {isPublishing ? (
                  <div className="flex gap-2 items-center">
                    {" "}
                    <MantineLoader color="white" size={"xs"} />
                    Publishing
                  </div>
                ) : (
                  <div className="flex items-center gap-2 ">
                    {" "}
                    <i className="material-icons text-sm">upload</i>
                    Publish
                  </div>
                )}
              </Button>
              <Button variant="filled" size="xs" onClick={exportData}>
                <i className="material-icons pr-2 text-sm">ios_share</i>Export
              </Button>
              {branchSelected.location === "Branch" && (
                <>
                  <Button variant="light" color="red" size="xs" onClick={open}>
                    <i className="material-icons pr-1">delete_outline</i>
                    Clear All
                  </Button>
                </>
              )}
            </div>
          </div>
        </header>
        {!loading && (
          <section className="flex gap-2 text-[0.7rem] overflow-hidden">
            <div className="branches-list  items-center flex flex-col gap-2 overflow-y-auto border border-gray">
              <div className="font-bold text-[0.8rem] sticky top-0 bg-white w-full text-center p-1">
                Select Branch
              </div>
              <div className="branches flex flex-col w-full h-full">
                <div
                  className={`branch-menu font-bold cursor-pointer border border-1 border-gray py-1 px-1 ${
                    branchSelected.name === "All"
                      ? "bg-tertiary text-white"
                      : "hover:bg-stone-50"
                  }`}
                  onClick={() =>
                    handleBranchChange({ name: "All", location: "Company" })
                  }
                >
                  All
                </div>
                {branchesList
                  .filter(
                    (branch: { name: string; location: string }) =>
                      branch.name !== "null" && branch.name !== "undefined"
                  )
                  .map(
                    (
                      branch: { name: string; location: string },
                      index: number
                    ) => {
                      return (
                        <div
                          key={index}
                          className={`branch-menu  font-bold cursor-pointer border border-1 border-gray py-1 px-1 ${
                            branchSelected.name === branch.name
                              ? "bg-tertiary text-white"
                              : "hover:bg-stone-50"
                          } ${getBranchAlignment(branch)}  `}
                          onClick={() => handleBranchChange(branch)}
                        >
                          <div>{branch.name}</div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
            <GoalSettingTable
              tableHeaders={tableHeaders}
              tableData={tableData}
              filteredGoalData={filteredGoalData}
              setFilteredGoalData={setFilteredGoalData}
              branchSelected={branchSelected.name}
              level={branchSelected.location}
              calcData={calcData}
              setCalcData={setCalcData}
              calcDataInputRows={calcDataInputRows}
              setCalcDataInputRows={setCalcDataInputRows}
              getSumCalculation={getSumCalculation}
              activeCell={activeCell}
              setActiveCell={setActiveCell}
              highlight={highlight}
              setHighlight={setHighlight}
              handleSaveAll={handleSaveAll}
              saveAllTimeout={saveAllTimeout}
              hasUnsavedChanges={hasUnsavedChanges}
              setHasUnsavedChanges={setHasUnsavedChanges}
              user={user}
            />
          </section>
        )}
      </main>
      <Modal
        opened={opened}
        onClose={close}
        title={`Are you sure you want to clear all the fields for ${branchSelected.name} ?`}
        centered
        size="auto"
        overlayProps={{
          opacity: 0.4,
        }}
        withCloseButton={false}
      >
        <div className="flex gap-2 items-center">
          <Button variant="filled" color="red" onClick={handleClearAll}>
            {clearLoading ? (
              <MantineLoader color="white" size={"sm"} />
            ) : (
              "Clear"
            )}
          </Button>
          <Button variant="outline" color="gray" onClick={close}>
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal
        opened={openedChart}
        onClose={closeChart}
        title=""
        centered
        overlayProps={{
          opacity: 0.5,
        }}
      >
        <GoalSettingChart
          branchSelected={branchSelected}
          revenueData={filteredGoalData?.find(
            (data: any) => data.items === ITEMS.NETSALES
          )}
          yearChartData={getYearChartData()}
          chartHeader={branchChartHeader}
        />
      </Modal>
    </>
  );
};

export default GoalSetting;
