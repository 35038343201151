export const getMonthWiseSumOfData = (data: any, months: string[]) => {
  const sum = data
    ? months.reduce(
        (sum, month) =>
          sum + getFixedValue(data[month] === "" ? 0 : data[month]),
        0
      )
    : 0;
  return isNaN(sum) ? 0 : sum;
};

export const getCalculatedPercentage = (
  value: number,
  netSalesValue: number
) => {
  const perc = (value / netSalesValue) * 100;
  return isNaN(perc) ? 0 : perc;
};

export const getPerOverLastYear = (
  thisYearGoal: number,
  lastYearGoal: number
) => {
  const perc =
    lastYearGoal && lastYearGoal !== 0
      ? (thisYearGoal / lastYearGoal - 1) * 100
      : 0;
  return isNaN(perc) ? 0 : getFixedValue(perc);
};

export const getFixedValue = (value: number) => {
  const numericValue = typeof value === "string" ? parseFloat(value) : value;
  const validNumber =
    isNaN(numericValue as number) || numericValue == null ? 0 : numericValue;
  return parseFloat(validNumber.toFixed(4));
};

export const getTemPayrollTaxes = (
  netSalesData?: any,
  qtrPercData?: any,
  header?: string,
  salesPayrollValue?: any,
  salesCommissionValue?: any,
  officePayrollValue?: any,
  qtrSumData?: any,
  taxRate?: {
    totalTaxRate: number;
    salesTaxRate: number;
    officeTaxRate: number;
  }
) => {
  const result = parseFloat(netSalesData) * (taxRate?.totalTaxRate ?? 0);
  return isNaN(result) ? 0 : result;
};

//Sales Expenses
export const getSalesTax = (
  netSalesData?: any,
  qtrPercData?: any,
  header?: string,
  salesPayrollValue?: any,
  salesCommissionValue?: any,
  officePayrollValue?: any,
  qtrSumData?: any,
  taxRate?: {
    totalTaxRate: number;
    salesTaxRate: number;
    officeTaxRate: number;
  }
) => {
  const result =
    (parseFloat(salesPayrollValue) + parseFloat(salesCommissionValue)) *
    (taxRate?.salesTaxRate ?? 0);
  return isNaN(result) ? 0 : result;
};

export const getNetSalesMultiplyQtrPerc = (
  netSalesData: string,
  qtrPercData: string
) => {
  const result = parseFloat(netSalesData) * (parseFloat(qtrPercData) / 100);
  return isNaN(result) ? 0 : result;
};

export const getQuarterSumDivideBy3 = (
  netSalesData: string,
  qtrPercData: string,
  header: any,
  salesPayrollValue: string,
  salesCommissionValue: string,
  officePayrollValue: string,
  qtrSumData: string
) => {
  const result = parseFloat(qtrSumData) / 3;
  return isNaN(result) ? 0 : result;
};

export const getSupportCenter = (netSalesData: string) => {
  const result = parseFloat(netSalesData) * 0.03;
  return isNaN(result) ? 0 : result;
};
