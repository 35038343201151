import { groupByKey } from "../../../../utils/group";
import { HOUSTON_BRANCHES } from "../../../../utils/helper/constants";

export const createHierarchy = (data: any, hierarchy: any) => {
  const result: any = [];
  const buildLevel = (data: any, level: any) => {
    if (level >= hierarchy.length) return;
    const levelKey = hierarchy[level];
    const grouped: any = {};

    data.forEach((item: any) => {
      const key = item[levelKey];
      if (!grouped[key]) grouped[key] = [];
      grouped[key].push(item);
    });
    const definedKeys = Object.keys(grouped).filter((key) => key !== "null");
    const nullKeys = Object.keys(grouped).filter((key) => key === "null");
    if (levelKey === "District") {
      nullKeys.forEach((key) => {
        result.push({ name: key, location: levelKey });

        buildLevel(grouped[key], level + 1);
      });
      definedKeys.forEach((key) => {
        result.push({ name: key, location: levelKey });
        buildLevel(grouped[key], level + 1);
      });
    } else {
      definedKeys.forEach((key) => {
        result.push({ name: key, location: levelKey });
        buildLevel(grouped[key], level + 1);
      });
      nullKeys.forEach((key) => {
        result.push({ name: key, location: levelKey });

        buildLevel(grouped[key], level + 1);
      });
    }
  };
  buildLevel(data, 0);
  return result;
};

export const getBranchAlignment = (branch: {
  name: string;
  location: string;
}) => {
  return HOUSTON_BRANCHES.includes(branch.name)
    ? "font-normal pl-4"
    : branch.name === "QCSA"
    ? "font-normal pl-6"
    : branch.location === "Branch"
    ? "font-normal pl-10"
    : branch.location === "District"
    ? "pl-9"
    : branch.location === "Region"
    ? "pl-6"
    : branch.location === "Sub Area name"
    ? "pl-4"
    : branch.location === "Areaname"
    ? "pl-2"
    : "";
};

export const formatExportData = (data: any, headers: any) => {
  return data.map((item: any) => {
    let formattedItem: any = {};
    if (item["items"] && item["items"].includes(":")) {
      headers.forEach((header: string) => {
        if (header === "") {
          formattedItem[header] = item["items"];
        } else {
          formattedItem[header] = null;
        }
      });
      return formattedItem;
    }
    headers.forEach((header: string) => {
      if (item.hasOwnProperty(header)) {
        formattedItem[header] =
          isNaN(item[header]) || !isFinite(item[header]) ? 0 : item[header];
      } else if (header.includes("QTR")) {
        const quarterKey = header.replace("QTR", "Quarter");
        formattedItem[header] = item[quarterKey] || 0;
      } else if (header === "") {
        formattedItem[header] = item["items"] || null;
      } else {
        formattedItem[header] = null;
      }
    });
    return formattedItem;
  });
};

export const formatChartData = (rawData: any, key: string) => {
  const data = groupByKey(rawData, key);
  let formattedChartData = {};
  Object.keys(data).forEach((v) => {
    formattedChartData = {
      ...formattedChartData,
      [v]: groupByKey(data[v], "calendaryear"),
    };
  });
  return formattedChartData;
};
