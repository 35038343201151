import { GetRequest, PutRequest } from "../plugins/https";

export const APIGoalSettingData = () => {
  return GetRequest(`goal-setting/table-data`);
};

export const APIUpdateTableRow = (editedRowData: {
  branch: string;
  categories: string;
  items: string;
  changes: {};
  user: string;
}) => {
  return PutRequest(`goal-setting/goal-value`, editedRowData);
};

export const APIBulkUpdate = (
  calculatedData: any,
  branchSelected: { name: string; location: string },
  user: any
) => {
  return PutRequest(`goal-setting/bulk-goal-value`, {
    calculatedData,
    branchSelected,
    user,
  });
};

export const APIClearAll = (branch: string) => {
  return PutRequest(`goal-setting/clear-all`, { branch: branch });
};

export const APIGoalChartData = () => {
  return GetRequest(`goal-setting/chart-data`);
};

export const APIPublishGoalData = () => {
  return GetRequest(`goal-setting/publish`);
};

export const APIChartHeaders = () => {
  return GetRequest(`goal-setting/chart-headers`);
};
