/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { displayColumns, headers } from "./utils/displayColumns";
import { formatArAGingCell } from "../../../utils/helper/cellFormatter";
import { useDispatch } from "react-redux";
import {
  setArCommentBranch,
  setArCommentDialog,
} from "../../../store/ArAging/actions";

// @ts-ignore
export const ArRowSubtotal = (props) => {
  const dispatch = useDispatch();
  const openCommentDialog = (
    header: { label: string; value: string },
    branch: string
  ) => {
    if (header.label === "Client Name") {
      dispatch(setArCommentBranch(branch));
      dispatch(setArCommentDialog(true));
    }
  };
  return (
    <div
      className={`ar-table-row flex ${
        props.activeHeader === "Client Summary" ? "" : "sub-total"
      }`}
      style={{
        marginBottom: props.activeHeader === "Client Summary" ? "0" : "12px",
      }}
    >
      {headers.map((v: any, key: number) => (
        <div
          onClick={() => openCommentDialog(v, props.header[v.value])}
          className={`ar-table-col flex  justify-between items-center ${props.isClientSummary(
            props.activeHeader,
            v.label
          )} ${v.value === "Organization" ? "pointer" : ""}`}
          key={key}
        >
          {formatArAGingCell(
            key,
            displayColumns(
              "SubTotal",
              v.value,
              props.header[v.value],
              props.multiSelect
            )
          )}
          {v.label === "Client Name" && props.isVMSClient && (
            <i className="material-icons text-[1rem]">star_border</i>
          )}
        </div>
      ))}
    </div>
  );
};
