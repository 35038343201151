/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import ReactApexChart from "react-apexcharts";
import { Component, useEffect, useState } from "react";
import { getWeekDataForClients } from "./utils/weekdata";
import { formatAmount } from "../../../utils/helper/cellFormatter";

const ClientSummaryChart = (props) => {
  // const weekLabels = useSelector((state) => state.filterReducer.weekLabels);
  const weekLabels = props.weeksClientData
    ? props.weeksClientData
        .filter((data) => data.CalendarYear === new Date().getFullYear())
        .map((data) => data.WeekEndingDate)
    : [];
  const weekNumbers = props.weeksClientData
    ? props.weeksClientData
        .filter((data) => data.CalendarYear === new Date().getFullYear())
        .map((data) => data.CalendarWeekNumber)
    : [];

  const chartColors = [
    { label: 2020, color: "#A63E2E" },
    { label: 2021, color: "#A66999" },
    { label: 2022, color: "#1875B5" },
    { label: 2023, color: "#FF7F0E" },
    { label: 2024, color: "#29A229" },
  ];
  const years = props.data ? Object.keys(props.data) : [];

  const tempChartData = {
    2021: [],
    2022: [],
    2023: [],
    2024: [],
  };

  // useEffect(() => {
  //   if (props.data) {
  //     const weekKeys = props.data[
  //       "2024" ? "2024" : "2023" ? "2023" : "2022" ? "2022" : "2021"
  //     ]?.map((w) => w.CalendarWeekKey);
  //     setFormattedWeeks(
  //       weekKeys?.map(
  //         (d) => d && moment(d?.toString(), "YYYYMMDD")?.format("YYYY-MM-DD")
  //       ) ?? []
  //     );
  //   }
  // }, [props.data]);

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: chartColors
      .filter(({ label }) => years.includes(label.toString()))
      .map(({ color }) => color),
    title: {},
    legend: {
      position: "top",
      show: false,
      horizontalAlign: "right",
      tooltipHoverFormatter: function (val, opts) {
        return val;
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          fontSize: "7px",
        },
        formatter: (v) => formatAmount(v),
      },
      max: props.sameClientRatio
        ? props.dataMode === "Revenue"
          ? props.totalData.RevSARMaxValueClients
          : props.totalData.GPSARMaxValueClients
        : undefined,
      min: props.sameClientRatio
        ? props.dataMode === "Revenue"
          ? props.totalData.RevSARMinValueClients
          : props.totalData.GPSARMinValueClients
        : undefined,
      tickAmount: 5,
    },
    stroke: {
      width: 1,
      dashArray: 0,
      curve: "straight",
    },
    markers: {
      size: 1,
      strokeWidth: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: "5px",
        },
      },
      categories: weekLabels,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  });
  useEffect(() => {
    if (props.data && Object.keys(props.data).length > 0) {
      setChartData();
    }
  }, [props.data, props.dataMode]);

  const setChartData = () => {
    // const series = Object.keys(tempChartData).map((key, i) => {
    //   return {
    //     name: key,
    //     data: [
    //       ...getWeekDataForClients(
    //         { ...tempChartData, ...props.data }[key],
    //         props.dataMode,
    //         key,
    //       ),
    //     ],
    //   };
    // });
    const series = Object.keys(props.data).map((key, i) => {
      return {
        name: key,
        data: [
          ...getWeekDataForClients(
            { ...props.data }[key],
            props.dataMode,
            key,
            weekNumbers
          ),
        ],
      };
    });

    setOptions({
      ...options,
      stroke: {
        width: 1,
        dashArray: 0,
        curve: "straight",
      },
      colors: chartColors
        .filter(({ label }) => years.includes(label.toString()))
        .map(({ color }) => color),
      markers: {
        size: 1,
        strokeWidth: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        labels: {
          style: {
            fontSize: "5px",
          },
        },
        categories: weekLabels,
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            fontSize: "7px",
          },
          formatter: (v) => formatAmount(v),
        },
        max: props.sameClientRatio
          ? props.dataMode === "Revenue"
            ? props.totalData.RevSARMaxValueClients
            : props.totalData.GPSARMaxValueClients
          : undefined,
        min: props.sameClientRatio
          ? props.dataMode === "Revenue"
            ? props.totalData.RevSARMinValueClients
            : props.totalData.GPSARMinValueClients
          : undefined,
        tickAmount: 5,
      },
    });
    setSeries(series);
  };

  useEffect(() => {
    setOptions({
      ...options,
      xaxis: {
        labels: {
          style: {
            fontSize: "5px",
          },
        },
        categories: weekLabels,
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            fontSize: "7px",
          },
          formatter: (v) => formatAmount(v),
        },
        max: props.sameClientRatio
          ? props.dataMode === "Revenue"
            ? props.totalData.RevSARMaxValueClients
            : props.totalData.GPSARMaxValueClients
          : undefined,
        min: props.sameClientRatio
          ? props.dataMode === "Revenue"
            ? props.totalData.RevSARMinValueClients
            : props.totalData.GPSARMinValueClients
          : undefined,
        tickAmount: 5,
      },
    });
  }, [props.sameClientRatio]);

  return (
    <div>
      <div className="chart-header">
        <div className="flex items-center justify-center w-full">
          <div>
            <div className="chart-header-title text-center">
              <div className="flex gap-2 items-center">
                <span>{props.name.label}</span>
                {props.isVMS && (
                  <i className="material-icons text-[1.2rem]">star_border</i>
                )}
              </div>
              <div>Rank: {props.name.rank}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="chart-area">
        <div className="chart-legend-area">
          <div className="colors-area">
            {chartColors.map((v, key) => (
              <div className="color-box" key={key}>
                <div
                  className="color-circle"
                  style={{ backgroundColor: `${v.color}` }}
                ></div>
                <div className={"color-name"}>{v.label}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={"h-full flex-grow"}>
          <ApexChart options={options} series={series} />
        </div>
      </div>
    </div>
  );
};

class ApexChart extends Component {
  render() {
    return (
      <>
        <ReactApexChart
          options={this.props.options}
          series={this.props.series}
          type="line"
          height={250}
        />
      </>
    );
  }
}

export default ClientSummaryChart;
