/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */
import { DeleteRequest, GetRequest, PutRequest } from "../plugins/https";

export const APIFetchLineChart = (level: string, location: string) => {
  return GetRequest("/bonus-review/chart", {
    params: { level, location },
  });
};

export const APIFetchEmployeeAndManager = (location: string) => {
  return GetRequest("/bonus-review/employee-manager", {
    params: { location },
  });
};

export const APIFetchScoreAndTasks = (location: string) => {
  return GetRequest("/bonus-review/score-and-tasks", {
    params: { location },
  });
};

export const APIFetchManagerDetails = () => {
  return GetRequest("/bonus-review/score-and-tasks");
};

export const APIInsertUpdateBonusReview = (
  id: string,
  location: string,
  employeeScore: number,
  managerScore: number,
  task: string,
  updatedBy: string
) => {
  return PutRequest("/bonus-review/", {
    id,
    location,
    employeeScore,
    managerScore,
    task,
    updatedBy,
  });
};

export const APICalculateTotalScore = (location: string) => {
  return GetRequest("/bonus-review/total-score", {
    params: { location },
  });
};

export const APIDeleteBonusReview = (id: number) => {
  return DeleteRequest("/bonus-review", {
    params: { id },
  });
};
