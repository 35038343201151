/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { DeleteRequest, GetRequest, PostRequest, PutRequest } from "../plugins/https";

export const APIGetClientRetentionDateRange = () => {
  return GetRequest(`/client-retention/date-range`);
};

export const APIGetClientsLostData = (location: string) => {
  return GetRequest(
    `/client-retention/clients-lost${location === "All" ? "" : "/" + location}`
  );
};

export const APIGetClientsRetainedUpData = (location: string) => {
  return GetRequest(
    `/client-retention/clients-retained-up${
      location === "All" ? "" : "/" + location
    }`
  );
};

export const APIGetClientsRetainedDownData = (location: string) => {
  return GetRequest(
    `/client-retention/clients-retained-down${
      location === "All" ? "" : "/" + location
    }`
  );
};

export const APIGetClientsGainedData = (location: string) => {
  return GetRequest(
    `/client-retention/clients-gained${
      location === "All" ? "" : "/" + location
    }`
  );
};

export const APIGetClientsRetentionTotalData = (location: string) => {
  return GetRequest(
    `/client-retention/metric-total${location === "All" ? "" : "/" + location}`
  );
};

export const APIGetOverallTotalData = (location: string) => {
  return GetRequest(
    `/client-retention/overall-total${location === "All" ? "" : "/" + location}`
  );
};

export const APIUpdateGrowthGoal = (data: any) => {
  return PutRequest(`/client-retention/growth-goal`, data);
};

export const APIAddNewClient = (data: any) => {
  return PostRequest(`/client-retention/client-gained`, data);
};

export const APIDeleteClientGained = (ID: string) => {
  return DeleteRequest(`/client-retention/client-gained/${ID}`);
};

export const APIUpdateNewClient = (data: any) => {
  return PutRequest(`/client-retention/client-gained`, data);
};
