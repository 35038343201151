export const quarterMonths: any = {
  Q1: ["January", "February", "March"],
  Q2: ["April", "May", "June"],
  Q3: ["July", "August", "September"],
  Q4: ["October", "November", "December"],
};

export const quarterPercKey: any = {
  Q1: "1st %",
  Q2: "2nd %",
  Q3: "3rd %",
  Q4: "4th %",
};

export const quarterSumKey: any = {
  Q1: "1st Quarter",
  Q2: "2nd Quarter",
  Q3: "3rd Quarter",
  Q4: "4th Quarter",
};

export const INPUT_ROWS = [
  "Net Sales",
  "Sales Payroll-Base",
  "Sales Commission",
  "Office Payroll",
  "SM Income Calc",
];

export const QUARTERLIST = [
  { quarter: "1st Quarter", quarterPercentage: "1st %", name: "Q1" },
  { quarter: "2nd Quarter", quarterPercentage: "2nd %", name: "Q2" },
  { quarter: "3rd Quarter", quarterPercentage: "3rd %", name: "Q3" },
  { quarter: "4th Quarter", quarterPercentage: "4th %", name: "Q4" },
];

export const OFFICE_ROWS = [
  "Office Support Payroll",
  "Office Supplies",
  "Forms & Printing",
  "Telephone",
  "Postage/Delivery",
  "Rent",
  "Utilities",
  "Recruiting",
  "Bank Charges",
  "Janitorial",
  "Health & Life Insurance",
  "Dues & Subscriptions",
  "Tax & Licenses",
  "Repairs & Maintenance",
  "Insurance",
  "Legal Fees",
  "Accounting Fees",
  "Support Services",
  "Outside Services",
  "Computer Services",
  "Advertising",
  "Training & Seminars",
  "Depreciation/Amortization",
  "Gifts & Entertainment",
  "Travel & Auto Expenses",
  "Interest Expense",
  "Bad Debts",
  "Miscellaneous",
];

export const TEMP_ROWS = [
  "Temporary Payroll",
  "Temp Payroll Taxes",
  "Temporary Medical Exp",
  "Temporary ACA",
  "Temp Mis Expenses",
];

export const SALES_ROWS = [
  "Sales P/R Tax",
  "Business Development",
  "Travel & Auto Expenses",
];

export const HIGHLIGHTED_PARTICULARS = [
  "Net Sales",
  "Total Temp Expenses",
  "Gross Profit",
  "Total Sales Expenses",
  "Total Operating Expenses",
  "Total Office Expenses",
  "Net Income(Loss)",
];

export enum CATEGORIES {
  TEMP = "Temporary Expenses",
  SALES = "Sales Expenses",
  OFFICE = "Office Expenses",
}

export enum ITEMS {
  STAFFINGMANAGER = "Staffing Managers (#)",
  INCOMEMODEL = "Income Model",
  NETSALES = "Net Sales",
  ACQUISITION_EXPENSE = "Acquisition Expense",
  INCOMEMODELPERC = "Income Model %",
  UNDERINCOMEMODEL = "SM Income Calc",
  OPERATINGINCOME = "Operating Income (Loss)",
  GROSSPROFIT = "Gross Profit",
}

export enum CATEGORIES {
  INCOME = "Income",
  OTHERS = "Others",
}

export const NULL_CELLS = [
  "Staffing Managers (#)",
  "Income Model %",
  "SM Income Calc",
];
export const NULL_ITEMS = [
  "GP to Operating Income",
  "Acquisition Expense",
  "SM Income Calc",
];

export const TotalFiveWeeks = ["March", "June", "September", "December"];

export const hierarchy = [
  "Company",
  "Areaname",
  "Sub Area name",
  "Region",
  "District",
  "Branch",
];

export enum LEVELS {
  COMPANY = "Company",
  AREA = "Areaname",
  SUBAREA = "Sub Area name",
  REGION = "Region",
  DISTRICT = "District",
  BRANCH = "Branch",
}

export const PerOverLYRows = [ITEMS.NETSALES, ITEMS.GROSSPROFIT];

export const YTD_HEADERS = ["YTD", "YTD %", "% Over LY"];
export const QUARTER_HEADERS = [
  "1st QTR",
  "1st %",
  "",
  "2nd QTR",
  "2nd %",
  "",
  "3rd QTR",
  "3rd %",
  "",
  "4th QTR",
  "4th %",
];
