import { Table } from "@mantine/core";
import {
  formatAmount,
  sortByKeyDailyNumbers,
} from "../../../utils/helper/cellFormatter";
import { useEffect, useState } from "react";
import {
  APIAddNewClient,
  APIDeleteClientGained,
  APIUpdateGrowthGoal,
  APIUpdateNewClient,
} from "../../../api/client-retention";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/Filter/actions";
import { Select } from "../../common/Select";

const ClientRetentionTable = (props: any) => {
  const dispatch = useDispatch();
  const {
    retentionData,
    timeline,
    branches,
    updateOverallTotalData,
    updateMetricsTotalData,
    clientGainedServices,
    currentBranch,
  } = props;
  const [activeCellData, setActiveCellData] = useState<any>({});
  const [activeCellName, setActiveCellName] = useState("");
  const [newCellData, setNewCellData] = useState<any>({
    ClientName: "",
    Branch: "",
    Revenue: "",
    GrowthGoal: 0,
    // Types: timeline,
    WeekEndingDate: "",
  });
  const [activeCellValue, setActiveCellValue] = useState<any>("");
  const [showEmptyRow, setShowEmptyRow] = useState<Boolean>(false);

  const handleGrowthGoalChange = async (e: any) => {
    e.preventDefault();
    const currentValue = retentionData.data.find(
      (d: any) => d === activeCellData
    );
    if (activeCellValue && activeCellValue !== currentValue.GrowthGoal) {
      currentValue.GrowthGoal = activeCellValue;
      dispatch(setLoading(true));
      await APIUpdateGrowthGoal({
        clientName: activeCellData.ClientName,
        branch: activeCellData.Branch,
        metric: retentionData.title,
        timeline: timeline,
        weekEndingDate: activeCellData.WeekEndingDate,
        growthGoal: activeCellValue,
        user: localStorage.getItem("username") ?? "",
      });
      await updateOverallTotalData();
      await updateMetricsTotalData();
    }
    setActiveCellData({});
    setActiveCellValue("");
    setActiveCellName("");
    dispatch(setLoading(false));
  };

  const addNewClient = () => {
    setShowEmptyRow(true);
    // retentionData.data.unshift(newCellData);
  };

  const clearNewRow = () => {
    setShowEmptyRow(false);
    setActiveCellData({});
    setActiveCellValue("");
  };

  function updateClientGainedRow(
    key: keyof typeof newCellData,
    data: number | string
  ) {
    setNewCellData((prev: any) => ({ ...prev, [key]: data }));
  }

  /**
   * Checks if required fields have value before allowing to save
   */
  const allFieldsHaveValue = () => {
    return Object.entries(newCellData).every(([key, value]) =>
      ["ClientName", "Branch"].includes(key) ? Boolean(value) : true
    );
  };

  // Stores the client data added in this session
  const [currentSessionClients, setCurrentSessionClients] = useState<
    Array<any>
  >([]);
  // store the client data of currently selected timeline
  const [newClientsForSelectedTimeline, setNewClientsSelectedTimeline] =
    useState<Array<any>>([]);
  useEffect(() => {
    setNewClientsSelectedTimeline([
      ...currentSessionClients.filter((client) => client.Types === timeline),
    ]);
  }, [currentSessionClients, timeline]);

  // Clear clients created on this session on timeline and branch change
  useEffect(() => {
    setCurrentSessionClients([]);
  }, [currentBranch]);

  // Update editable row
  const [underUpdateRow, setUnderUpdateRow] = useState({});
  const isRowUnderUpdate = (row: any) => underUpdateRow === row;
  const [updatedRowData, setUpdatedRowData] = useState({
    ClientName: "",
    Branch: "",
    Revenue: "",
    GrowthGoal: 0,
    UpdatedBy: localStorage.getItem("username") ?? "",
    Metrics: retentionData.title,
  });

  function updateSelectedRow(
    key: keyof typeof updatedRowData,
    data: number | string
  ) {
    setUpdatedRowData((prev: any) => ({ ...prev, [key]: data }));
  }

  // Functions for saving, deleting and updating clients gained
  const services = {
    saveNewClient: async () => {
      try {
        dispatch(setLoading(true));
        const newClientData = {
          Metrics: retentionData.title,
          Types: timeline,
          UpdatedBy: localStorage.getItem("username") ?? "",
          ...newCellData,
        };
        const response = await APIAddNewClient(newClientData);
        await Promise.all([updateOverallTotalData(), updateMetricsTotalData()]);
        // clientGainedServices.add(response.data?.[0]?.[0]);
        setCurrentSessionClients((prev) => [response.data?.[0]?.[0], ...prev]);
        setShowEmptyRow(false);
        setNewCellData({
          ClientName: "",
          Branch: "",
          Revenue: 0,
          GrowthGoal: 0,
        });
      } catch (error) {
        // console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    },
    deleteClient: async (clientID: string) => {
      try {
        dispatch(setLoading(true));
        await APIDeleteClientGained(clientID);
        await updateOverallTotalData();
        await updateMetricsTotalData();
        setRowIdToDelete("none");
        clientGainedServices.delete(clientID);
        setCurrentSessionClients((prev) =>
          prev.filter((item) => item.ID !== clientID)
        );
      } catch (error) {
        // console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    },
    updateClient: async () => {
      if (
        JSON.stringify(Object.values(underUpdateRow).sort()) ===
        JSON.stringify(Object.values(updatedRowData).sort())
      ) {
        // value didn't change
      }
      try {
        dispatch(setLoading(true));
        await APIUpdateNewClient(updatedRowData);
        await updateOverallTotalData();
        await updateMetricsTotalData();
        setCurrentSessionClients((prev) =>
          prev.map((item) => {
            if (item.ID === (updatedRowData as Record<string, any>).ID) {
              return { ...item, ...updatedRowData };
            }
            return item;
          })
        );
        clientGainedServices.update(updatedRowData);
      } catch (error) {
        // console.log(error);
      } finally {
        dispatch(setLoading(false));
        setUnderUpdateRow({});
        setUpdatedRowData({
          ClientName: "",
          Branch: "",
          Revenue: "",
          GrowthGoal: 0,
          UpdatedBy: localStorage.getItem("username") ?? "",
          Metrics: retentionData.title,
        });
      }
    },
  };

  // Sort table columns

  const [sortingOptions, setSortingOptions] = useState<{
    item?: string;
    order?: "asc" | "desc" | "none" | string;
  }>({ item: "", order: "none" });

  const [sortedRetentionData, setSortedRetentionData] = useState<Array<any>>([
    ...retentionData.data,
  ]);

  /**
   * Handle the sorting of table columns when header is clicked
   */
  function handleSortToggle(item: string) {
    // Determine new sorting options based on the current state
    const newSortingOptions =
      sortingOptions.item === item
        ? {
            item,
            order:
              sortingOptions.order === "asc"
                ? "desc"
                : sortingOptions.order === "desc"
                ? "none"
                : "asc",
          }
        : { item, order: "asc" };

    setSortingOptions({
      item: newSortingOptions.item,
      order: newSortingOptions.order as "asc" | "desc" | "none",
    });

    // Whe sorting order changes
    // Add newly added data to, retention data
    if (currentSessionClients?.length > 0) {
      clientGainedServices.add(currentSessionClients);
      setCurrentSessionClients([]);
    }
  }

  /**
   * Renders table header with sorting functionality, and sort type icon
   */
  function SortableTableHeader({
    title,
    align = "center",
  }: {
    title: string;
    align?: "start" | "center";
  }) {
    const isSorted =
      sortingOptions.item === title && sortingOptions.order !== "none";

    return (
      <div
        className={`flex gap-1 items-center cursor-pointer select-none text-white ${
          align === "start" ? "justify-start pl-0.5" : `justify-center`
        }`}
        onClick={() => handleSortToggle(title)}
      >
        <p>{title}</p>
        {isSorted ? (
          <i
            className={`material-icons ${
              sortingOptions.order === "desc" ? "rotate-180" : ""
            }`}
          >
            arrow_drop_up
          </i>
        ) : (
          <i className="material-icons">remove</i>
        )}
      </div>
    );
  }

  /**
   * Sort the data by 'Revenue' in default.
   */
  useEffect(() => {
    if (!sortingOptions.item)
      setSortingOptions({ item: "Revenue", order: "desc" });

    const newSortedData =
      sortingOptions.order === "none" || !sortingOptions.item
        ? retentionData.data
        : sortByKeyDailyNumbers(
            retentionData.data,
            sortingOptions.item.replace(" ", ""),
            sortingOptions.order === "desc"
          );

    setSortedRetentionData([
      ...newClientsForSelectedTimeline,
      ...newSortedData,
    ]);
  }, [retentionData, newClientsForSelectedTimeline, sortingOptions]);

  const [rowIdToDelete, setRowIdToDelete] = useState<string>("none");

  return (
    <div className="clients-table w-1/4 flex flex-col gap-3 flex-1 overflow-hidden">
      <div className="header flex flex-col items-center gap-2">
        <div className="section-title bg-tertiary text-white w-40 font-bold text-xs text-center p-2">
          {retentionData?.title}
        </div>
        <div className="invoice-details text-[11px] flex justify-between w-full">
          <div className="last-invoice flex flex-col items-center bg-white w-48 py-1">
            <span className="font-bold">
              {retentionData?.dateRangeDetails?.lastTitle}
            </span>
            <span>{retentionData?.dateRangeDetails?.lastDateRange}</span>
          </div>
          <div className="previous-invoice flex flex-col items-center bg-white w-48 py-1">
            <span className="font-bold">
              {retentionData?.dateRangeDetails?.previousTitle}
            </span>
            <span>{retentionData?.dateRangeDetails?.previousDateRange}</span>
          </div>
        </div>
        <div className="flex items-center justify-between w-full">
          <div>
            {retentionData?.title === "Clients Gained" && !showEmptyRow && (
              <div
                className="flex items-center gap-1 text-xs px-2 py-[1px] rounded-md bg-blue-500 cursor-pointer hover:bg-blue-600 text-white"
                onClick={addNewClient}
              >
                <i className="material-icons text-white text-sm">add_circle</i>
                Add New Client
              </div>
            )}

            {/* Add/save new client button */}
            {retentionData?.title === "Clients Gained" && showEmptyRow && (
              <div className="flex gap-2">
                <div
                  className={`select-none flex items-center gap-1 text-xs px-2 py-[1px] rounded-md bg-blue-500 cursor-pointer hover:bg-blue-600 text-white ${
                    !allFieldsHaveValue() ? "opacity-85 cursor-not-allowed" : ""
                  }`}
                  onClick={() =>
                    allFieldsHaveValue() ? services.saveNewClient() : ""
                  }
                  title={`${
                    !allFieldsHaveValue()
                      ? "Fill in all the fields to save"
                      : ""
                  }`}
                >
                  <i className="material-icons text-white text-sm">
                    add_circle
                  </i>
                  Save Data
                </div>
                <div
                  className={`select-none flex items-center gap-1 text-xs px-2 py-[1px] rounded-md bg-red-400 cursor-pointer hover:bg-red-500 text-white`}
                  onClick={clearNewRow}
                  title="Cancel"
                >
                  <i className="material-icons text-white text-sm">cancel</i>
                  Cancel
                </div>
              </div>
            )}
          </div>
          <div className="text-xs font-bold py-1">
            Total: {formatAmount(retentionData?.totalValue)}
          </div>
        </div>
      </div>
      <div className="flex flex-1 overflow-auto bg-white">
        <div className="data-table w-full">
          <Table
            fontSize={10}
            className="bg-white"
            withColumnBorders
            verticalSpacing={2}
            horizontalSpacing={4}
          >
            <thead className="sticky top-0 z-30">
              <tr className="bg-tertiary text-white">
                <th>
                  <SortableTableHeader title="Client Name" align="start" />
                </th>
                <th style={{ color: "white", textAlign: "center" }}>
                  <SortableTableHeader title="Branch" />
                </th>
                <th style={{ color: "white", textAlign: "center" }}>
                  <SortableTableHeader title="Revenue" />
                </th>
                <th
                  style={{ color: "white", textAlign: "center" }}
                  className="w-16"
                >
                  <SortableTableHeader title="Growth Goal" />
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Add new client empty row */}
              {showEmptyRow && (
                <tr>
                  <td>
                    <input
                      autoFocus
                      type="text"
                      name=""
                      id=""
                      onChange={(e) =>
                        updateClientGainedRow(
                          "ClientName",
                          e.target.value.trim()
                        )
                      }
                    />
                  </td>
                  <td>
                    <Select
                      options={branches}
                      placeHolder="Branch"
                      onChange={(value) =>
                        updateClientGainedRow("Branch", value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="revenue"
                      id=""
                      className="h-5 w-full"
                      onChange={(e) =>
                        updateClientGainedRow("Revenue", e.target.value.trim())
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="growthGoal"
                      id=""
                      className="h-5 w-full"
                      onChange={(e) =>
                        updateClientGainedRow(
                          "GrowthGoal",
                          e.target.value.trim()
                        )
                      }
                    />
                  </td>
                </tr>
              )}

              {/* Existing clients data */}
              {sortedRetentionData?.map((d: any, i: any) => {
                return (
                  <tr
                    key={i}
                    className={`relative ${
                      d?.Editable && d?.ID !== rowIdToDelete
                        ? "border-l border-tertiary group/editable"
                        : ""
                    }
                    `}
                    onClick={() => setActiveCellData(d)}
                  >
                    {/* Client Name */}
                    <td className={`flex justify-between h-full p-0 m-0`}>
                      {isRowUnderUpdate(d) ? (
                        <div className="flex justify-between items-center w-full h-7 ">
                          <input
                            type="text"
                            defaultValue={d?.ClientName}
                            className="h-5"
                            onChange={(e: any) =>
                              updateSelectedRow(
                                "ClientName",
                                e.target.value.trim()
                              )
                            }
                          />
                          <span className={`flex gap-2`}>
                            <i
                              className={`material-icons text-gray-500 text-[15px] cursor-pointer`}
                              title="Cancel Update"
                              onClick={() => setUnderUpdateRow({})}
                            >
                              cancel
                            </i>
                            <i
                              className={`material-icons text-gray-500 text-[15px] cursor-pointer `}
                              title="save Update"
                              onClick={services.updateClient}
                            >
                              save
                            </i>
                          </span>
                        </div>
                      ) : (
                        <div className="flex justify-between items-center h-full w-full">
                          <p>{d?.ClientName}</p>
                          <span
                            className={`hidden group-hover/editable:flex gap-2`}
                          >
                            <i
                              className={`material-icons text-gray-500 text-[15px] cursor-pointer`}
                              title="Update client data"
                              onClick={() => {
                                setUnderUpdateRow(d);
                                setUpdatedRowData((prev) => ({
                                  ...prev,
                                  ...d,
                                }));
                              }}
                            >
                              edit
                            </i>
                            <i
                              className={`material-icons text-gray-500 text-[15px] cursor-pointer `}
                              title="Delete the client"
                              onClick={() => setRowIdToDelete(d?.ID)}
                            >
                              delete
                            </i>
                          </span>
                        </div>
                      )}
                      <span>
                        {d?.Label === "VMS" && (
                          <i className="material-icons text-gray-500 text-[15px]">
                            star_outline
                          </i>
                        )}
                      </span>
                    </td>

                    {/* Branch */}
                    <td className="text-center">
                      {isRowUnderUpdate(d) ? (
                        <Select
                          options={branches}
                          placeHolder="Select a branch"
                          defaultValue={d?.Branch}
                          onChange={(value) =>
                            updateSelectedRow("Branch", value)
                          }
                        />
                      ) : (
                        d?.Branch
                      )}
                    </td>
                    {/* Revenue*/}
                    <td className="text-right">
                      {isRowUnderUpdate(d) ? (
                        <input
                          type="number"
                          name=""
                          id=""
                          className="h-5 w-full pl-1"
                          defaultValue={d?.Revenue}
                          onChange={(e) => {
                            updateSelectedRow("Revenue", e.target.value.trim());
                          }}
                        />
                      ) : (
                        formatAmount(d?.Revenue)
                      )}
                    </td>

                    {/* Growth Goal */}
                    <td
                      className="text-right"
                      onClick={() => {
                        if (!isRowUnderUpdate(d)) {
                          setActiveCellValue(d?.GrowthGoal || "");
                          setActiveCellName("GrowthGoal");
                        }
                      }}
                    >
                      {isRowUnderUpdate(d) ? (
                        <input
                          type="number"
                          name=""
                          id=""
                          className="h-5 pl-1 w-full"
                          defaultValue={d?.GrowthGoal}
                          onChange={(e) => {
                            updateSelectedRow(
                              "GrowthGoal",
                              e.target.value.trim()
                            );
                          }}
                        />
                      ) : activeCellData === d &&
                        activeCellName === "GrowthGoal" ? (
                        <form action="" onSubmit={handleGrowthGoalChange}>
                          <input
                            autoFocus
                            type="number"
                            name=""
                            id=""
                            className="h-5 w-full"
                            value={activeCellValue}
                            onBlur={(e) => {
                              setActiveCellValue(e.target.value);
                              handleGrowthGoalChange(e);
                            }}
                            onChange={(e) => {
                              setActiveCellValue(e.target.value);
                            }}
                          />
                        </form>
                      ) : (
                        formatAmount(d?.GrowthGoal)
                      )}
                    </td>

                    {/* Delete confirmation overlay */}
                    {d?.ID === rowIdToDelete && (
                      <div className="flex  items-center bg-white text-sm w-full border border-red-400 absolute top-0 left-0 px-1 m-0 z-10">
                        <p className="text-[10px]">
                          Are you sure you want to delete this client?
                        </p>
                        <span className="flex gap-2 ml-4">
                          <i
                            className="material-icons text-gray-500 hover:text-gray-600 cursor-pointer text-sm"
                            title="confirm deletion"
                            onClick={() => services.deleteClient(d?.ID)}
                          >
                            check_circle
                          </i>
                          <i
                            className="material-icons text-gray-500 hover:text-gray-600 cursor-pointer text-sm"
                            title="cancel deletion"
                            onClick={() => setRowIdToDelete("none")}
                          >
                            cancel
                          </i>
                        </span>
                      </div>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ClientRetentionTable;
