import { Select } from "@mantine/core";
import { useEffect, useState } from "react";
import { APIGetCompanySummaryRegionsWithoutVirtual } from "../../../api/company-summary";
import { formatLocationData } from "../../../utils/helper/slicerListFormatter";

export const BonusReviewFilters = (props: any) => {
  const { selectedFilter, setSelectedFilter, setSelectedLevel } = props;
  const [_areaList, setAreaList] = useState(["All"]);
  const [subareaList, setSubAreaList] = useState(["All"]);
  const [regionList, setRegionList] = useState(["All"]);
  const [districtList, setDistrictList] = useState(["All"]);
  const [branchList, setBranchList] = useState(["All"]);
  const [location, setLocation] = useState<any>([]);
  const [heirarchy, setHierarchy] = useState<any>({});
  const [showDistrictSlicer, setShowDistrictSlicer] = useState(true);
  const [defaultLocation, setDefaultLocation] = useState<any>({
    areas: [],
    subareas: [],
    regions: [],
    districts: [],
    branches: [],
  });

  const fetchRegionsData = async () => {
    const res = await APIGetCompanySummaryRegionsWithoutVirtual();
    setLocation(res.data);
    const { areas, subareas, regions, districts, branches, locationHier } =
      formatLocationData(res.data);
    defaultLocation.areas = areas;
    defaultLocation.subareas = subareas;
    defaultLocation.regions = regions;
    defaultLocation.districts = districts;
    defaultLocation.branches = branches;
    setHierarchy(locationHier);
    setDefaultLocation(defaultLocation);
    setAreas(defaultLocation.areas);
    setSubAreas(defaultLocation.subareas);
    // set default subarea
    setSelectedFilter((prev: any) => ({
      ...prev,
      subarea: defaultLocation.subareas[0],
    }));
    setSelectedLevel("Sub Area");
    setRegions(defaultLocation.regions);
    setDistricts(defaultLocation.districts);
    setBranches(defaultLocation.branches);
  };

  useEffect(() => {
    fetchRegionsData();
  }, []);

  const setAreas = (areas: any) => {
    setAreaList(["All", ...areas]);
  };
  const setSubAreas = (subareas: any) => {
    setSubAreaList(["All", ...subareas]);
  };
  const setRegions = (regions: any) => {
    setRegionList(["All", ...regions]);
  };
  const setDistricts = (districts: any) => {
    setDistrictList(["All", ...districts]);
  };
  const setBranches = (branches: any) => {
    setBranchList([
      "All",
      ...branches.sort((a: any, b: any) => {
        return a > b ? 1 : a < b ? -1 : 0;
      }),
    ]);
  };

  const changeSubAreaSelection = (value: any) => {
    setSelectedLevel("Sub Area");
    setSelectedFilter((prev: any) => ({
      ...prev,
      subarea: value,
      region: "All",
      district: "All",
      branch: "All",
    }));
    if (value === "All") {
      setSelectedLevel("All");
      setSelectedFilter((prev: any) => ({
        ...prev,
        area: "All",
      }));
      setRegions(defaultLocation.regions);
      setDistricts(defaultLocation.districts);
      setBranches(defaultLocation.branches);
    } else {
      const regions: any = [];
      const districts: any = [];
      const branches: any = [];
      location.map((l: any) => {
        if (l["Sub Area Name"] === value) {
          if (!regions.includes(l.Region)) regions.push(l.Region);
          if (!districts.includes(l.District)) districts.push(l.District);
          branches.push(l.Branch);
        }
      });
      setRegions(regions.filter((d: any) => !!d));
      setDistricts(districts.filter((d: any) => !!d));
      setBranches(branches.filter((d: any) => !!d));
    }
  };

  const changeRegionSelection = (value: any) => {
    setSelectedLevel("Region");
    setSelectedFilter((prev: any) => ({
      ...prev,
      region: value,
      district: "All",
      branch: "All",
    }));
    if (value === "All") {
      setSelectedLevel("All");
      setSelectedFilter((prev: any) => ({
        ...prev,
        area: "All",
        subarea: "All",
      }));
      const r: any = [],
        b: any = [];
      Object.keys(heirarchy[selectedFilter.area]).map((region: any) => {
        if (region && region !== "Ungrouped") r.push(region);
        heirarchy[selectedFilter.area][region].map((branch: any) => {
          if (branch) b.push(branch);
        });
      });
      setRegions(r);
      setBranches(b);
      setDistricts(defaultLocation.districts);
    } else {
      const selectedLocation = location.find((l: any) => l.Region === value);
      const districts: any = [];
      const branches: any = [];
      location.map((l: any) => {
        if (l.Region === value) {
          if (!districts.includes(l.District)) districts.push(l.District);
          branches.push(l.Branch);
        }
      });
      setSelectedFilter((prev: any) => ({
        ...prev,
        area: selectedLocation?.Areaname ?? "All",
        subarea: selectedLocation["Sub Area Name"] ?? "All",
      }));
      setBranches(branches.filter((d: any) => !!d));
      setDistricts(districts.filter((d: any) => !!d));
      districts && districts.filter((d: any) => d !== null).length !== 0
        ? setShowDistrictSlicer(true)
        : setShowDistrictSlicer(false);
    }
  };

  const changeDistrictSelection = (value: any) => {
    setSelectedLevel("District");
    setSelectedFilter((prev: any) => ({
      ...prev,
      district: value,
      branch: "All",
    }));

    if (value === "All") {
      setSelectedLevel("All");
      setSelectedFilter((prev: any) => ({
        ...prev,
        area: "All",
        subarea: "All",
        region: "All",
      }));
      setBranches(defaultLocation.branches);
    } else {
      const selectedLocation = location.find((l: any) => l.District === value);
      const branches: any = [];
      location.map((l: any) => {
        if (l.District === value) {
          branches.push(l.Branch);
        }
      });
      setSelectedFilter((prev: any) => ({
        ...prev,
        area: selectedLocation?.Areaname ?? "All",
        subarea: selectedLocation["Sub Area Name"] ?? "All",
        region: selectedLocation?.Region ?? "All",
      }));
      setBranches(branches.filter((d: any) => !!d));
    }
  };

  const changeBranchSelection = (value: any) => {
    setSelectedLevel("Branch");
    if (value !== "All") {
      const selectedLocation = location.find((l: any) => l.Branch === value);
      setSelectedFilter((prev: any) => ({
        ...prev,
        area:
          selectedLocation?.Areaname && selectedLocation.Areaname !== ""
            ? selectedLocation?.Areaname
            : "All",
        subarea: selectedLocation["Sub Area Name"] ?? "All",
        region: selectedLocation?.Region ?? "All",
        district: selectedLocation?.District ?? "All",
      }));
      if (selectedLocation?.District && selectedLocation.District !== null) {
        setSelectedFilter((prev: any) => ({
          ...prev,

          district: selectedLocation?.District,
        }));
        setShowDistrictSlicer(true);
      } else if (selectedLocation.District === null) {
        setShowDistrictSlicer(false);
      } else {
        setSelectedFilter((prev: any) => ({
          ...prev,

          district: "All",
        }));
        setShowDistrictSlicer(true);
      }
    } else {
      setSelectedLevel("All");
      setSelectedFilter((prev: any) => ({
        ...prev,
        area: "All",
        subarea: "All",
        region: "All",
        district: "All",
      }));
    }
    setSelectedFilter((prev: any) => ({
      ...prev,
      branch: value,
    }));
  };

  return (
    <div className="flex items-center gap-2">
      <Select
        className="w-24 sm:w-24"
        size="xs"
        label="Subarea"
        data={subareaList}
        value={selectedFilter.subarea}
        onChange={changeSubAreaSelection}
      />
      <Select
        className="w-24 sm:w-32"
        size="xs"
        label="Region"
        data={regionList}
        value={selectedFilter.region}
        onChange={changeRegionSelection}
      />
      {showDistrictSlicer && (
        <Select
          className="w-24 sm:w-32"
          size="xs"
          label="Area Branch"
          data={districtList}
          value={selectedFilter.district}
          onChange={changeDistrictSelection}
        />
      )}
      <Select
        className="w-24 sm:w-32"
        size="xs"
        label="Branch"
        data={branchList}
        value={selectedFilter.branch}
        onChange={changeBranchSelection}
      />
    </div>
  );
};
